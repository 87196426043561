import { AxiosService } from "../utils/axiosService";
import { stringDecompression } from "./stringLZService";

const UMS_URL = {
  GetGroupAndStoreByTypes:
    "https://api.gotsystems.net/api/UMS/GetGroupAndStoreByType",
  GetGroupAndGroupID: "https://api.gotsystems.net/api/Settings/GroupAndGroupID",

  /////////////////////////////////////////////////////////////////////
  UserListForSuperAdmin: "https://api.gotsystems.net/api/UMS/UserList",
  UserListForAdmin: "https://api.gotsystems.net/api/UMS/UserListForAdmin",
  UserListForGroup: "https://api.gotsystems.net/api/UMS/UserListForGroup",

  CreateUser: "https://api.gotsystems.net/api/UMS/SaveUserData",
  //CreateUser: "https://localhost:5001/api/UMS/SaveUserData",

  DeleteUser: "https://api.gotsystems.net/api/UMS/DeleteUser",
  ///////////////////////////////////////////////////////////////////////
  RoleList: "https://api.gotsystems.net/api/UMS/GetRolesList",
  CreateRole: "https://api.gotsystems.net/api/UMS/SaveRoleData",
  UpdateRole: "https://api.gotsystems.net/api/UMS/UpdateRoleData",
  DeleteRole: "https://api.gotsystems.net/api/UMS/DeleteRole",
  ////////////////////////////////////////////////////////////////////////

  GetUsersByRole: "https://api.gotsystems.net/api/UMS/GetUsersByRole",
  SearchLoginHistory: "https://api.gotsystems.net/api/UMS/GetLoginHistory",

  ////////////////////////////////////////////////////////////////////////

  GetLoginHistoryPortal: "https://api.gotsystems.net/api/UMS/GetLoginHistoryPortal",
  InsertLoginHistoryPortal:"https://api.gotsystems.net/api/UMS/InsertLoginHistoryPortal",
};

// UMS USERS
//#region User Managament System Insert User
export const InsertNewUser = (userData) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.CreateUser, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Update User
export const UpdateUser = (userData) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.CreateUser, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Get Group and GroupID
export const GetGroupandGroupID = () => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Get(UMS_URL.GetGroupAndGroupID)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System GetGroupAndStoreByTypes
export const GetGroupAndStoreByTypes = (IDType, operation) => {
  return new Promise<any>((resolve, reject) => {
    var GroupAndType = {
      idType: IDType.toString(),
      operation: operation.toString(),
    };
    AxiosService.Post(UMS_URL.GetGroupAndStoreByTypes, GroupAndType)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System User List For Super Admin
export const GetUserListForSuperAdmin = () => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Get(UMS_URL.UserListForSuperAdmin)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System User List For Admin
export const GetUserListForAdmin = (GroupID) => {
  const requestObject = {
    GroupID: GroupID.toString(),
    //GroupID : "35"
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.UserListForAdmin, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Delete User
export const DeleteUser = (userID) => {
  var userData = {
    userID: userID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.DeleteUser, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

// UMS ROLES

//#region User Managament System User List
export const GetRolesList = () => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Get(UMS_URL.RoleList)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Delete Role
export const DeleteRole = (roleID) => {
  var userData = {
    userID: roleID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.DeleteRole, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Insert Role
export const InsertNewRole = (userData) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.CreateRole, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Insert Role
export const UpdateNewRole = (userData) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.UpdateRole, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

// Login History

//#region User Managament System Get Group and GroupID
export const GetUsersByRole = (roleID) => {
  var data = {
    _roleID: roleID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.GetUsersByRole, data)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System Login History
export const SearchLoginHistory = (userData) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.SearchLoginHistory, userData)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament System User List For Group
export const GetUserListForGroup = (GroupID) => {
  const requestObject = {
    GroupID: GroupID.toString(),
    //GroupID : "35"
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.UserListForGroup, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region User Managament Login History

//#region Get Login History
export const GetLoginHistoryPortal = (userID) => {
  const userObj = {
    UserID: userID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.GetLoginHistoryPortal, userObj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Insert Login History
export const InsertLoginHistoryPortal = (obj) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(UMS_URL.InsertLoginHistoryPortal, obj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          if (data) resolve(JSON.parse(data));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#endregion
