import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import * as OrderService from "../services/orderService";
import { toast } from "react-toastify";
import {
  Filters,
  OrdersRequestFailed,
  OrdersRequestProcessed,
  OrdersRequestWaiting,
  OrdersRequestNotBuilt,
} from "../models"
import { Button, DatePicker } from "rsuite";
import moment from "moment";
import React from "react";

const tabsList: any[] = [
  { key: "All", label: "All" },
  { key: "Orders", label: "Orders" },
  { key: "Inventory", label: "Inventory" },
  { key: "Recruits", label: "Recruits" },
  { key: "Credits", label: "Credits" },
  { key: "Outs", label: "Outs" },
  { key: "Bad UPS", label: "Bad UPS" },
  { key: "Inquiry", label: "Inquiry" },
  { key: "Just Pull", label: "Just Pull" },
  { key: "No Cred", label: "No Cred" },
  { key: "No Order", label: "No Order" },
  { key: "Placement", label: "Placement" },
  { key: "Track IT", label: "Track IT" },
];
const DashboardComponent = (props: any) => {
  const [ordersRequest, setOrdersRequest] = useState(
    new OrdersRequestProcessed(moment().format("YYYY-MM-DD"))
  );
  const [selectedTab, setSelectedTab] = useState("All");
  const [orders, handleOrders] = useState([]);
  const [paginationFilters, setPaginationFilters] = useState(new Filters());
  const [activeButton, setActiveButton] = useState("Processed");
  useEffect(() => {
    GetOrdersList();
  }, [ordersRequest]);

  const GetOrdersList = () => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    OrderService.getOrdersList(ordersRequest)
      .then((result) => {
        const { data } = result;
        data.map((item) => {
          item.createdDateTime = moment(item.createdDateTime).format(
            "MM-DD-YYYY hh:mm A"
          );
          item.dateTimeIn = moment(item.dateTimeIn).format(
            "MM-DD-YYYY hh:mm A"
          );
          item.deadLineDateTime = moment(item.deadLineDateTime).format(
            "MM-DD-YYYY hh:mm A"
          );
          item.deliveryDate = moment(item.deliveryDate).format(
            "MM-DD-YYYY hh:mm A"
          );
          item.processedDate1 = moment(item.processedDate1).format(
            "MM-DD-YYYY hh:mm A"
          );
          item.sentDateTime = moment(item.sentDateTime).format(
            "MM-DD-YYYY hh:mm A"
          );
          item.processedDate1 = moment(item.processedDate1).format(
            "MM-DD-YYYY hh:mm A"
          );
          //item.color = "#d62915"

          return item;
        });
        BuildOrderData(data, (response) => {
          const { orders = [], paginationFilters = new Filters() } = response;
          handleOrders(orders);
          setPaginationFilters(paginationFilters);
          //toast.success(activeButton + 'Orders Loaded Successfully');
          toast.success(`${activeButton} Orders Loaded Successfully`);
        });
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        handleOrders([]);
        setPaginationFilters(new Filters());
        toast.error("No Orders Found");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const handlePaginationChange = (type: string, value: any) => {
    const _paginationFilter = { ...paginationFilters };
    switch (type) {
      case "currentPage":
        _paginationFilter.offset = value * _paginationFilter.currentPageSize;
        _paginationFilter.currentPage = value + 1;
        _paginationFilter.showingRecords = getShowingRecords(_paginationFilter);
        break;
      case "currentPageSize":
        _paginationFilter.offset = 0;
        _paginationFilter.currentPage = 1;
        _paginationFilter.totalPageCount =
          orders.length === 0 ? 0 : Math.ceil(orders.length / value);
        _paginationFilter.currentPageSize = value;
        _paginationFilter.showingRecords = getShowingRecords(_paginationFilter);
        break;

      default:
        _paginationFilter[type] = value;
        break;
    }

    setPaginationFilters(_paginationFilter);
  };

  const handleOrderRequest = (type, value) => {
    const _orderRequest = { ...ordersRequest };
    switch (type) {
      case "startDate":
        _orderRequest[`_startDate`] = value;
        break;

      default:
        _orderRequest[type] = value;
        break;
    }
    setOrdersRequest(_orderRequest);
  };

  const BuildOrderData = (orders: any[], callback: any | void) => {
    const _filters = { ...paginationFilters };
    _filters.totalRecords = orders.length;
    _filters.totalPageCount =
      orders.length === 0
        ? 0
        : Math.ceil(orders.length / _filters.currentPageSize);
    _filters.currentPage = 1;

    _filters.showingRecords = getShowingRecords(_filters);
    callback && callback({ orders, paginationFilters: _filters });
  };

  const getShowingRecords = (filters) => {
    let initCount = (filters.currentPage - 1) * filters.currentPageSize ?? 0;
    let showingRecords = `${initCount + 1}`;

    if (filters.currentPageSize > filters.totalRecords) {
      showingRecords += ` to ${filters.totalRecords}`;
    } else {
      showingRecords += ` to ${initCount + filters.currentPageSize}`;
    }
    return showingRecords;
  };

  const RenderOrderRows = () => {
    return (orders ?? [])
      .slice(
        paginationFilters.offset,
        paginationFilters.offset + paginationFilters.currentPageSize
      )
      .map((_orders, _orderIdx) => {
        return (
          <>
            <tr key={`OrderRows${_orderIdx}`}>
              {/*              <td rowSpan={1} colSpan={1}>
                {" "}
                <input
                  type={`checkbox`}
                  defaultChecked={_orders["mark1"] ?? false}
                />
              </td> */}
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["storeID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["fileType"] ?? ""}
              </td>
              <td className="white-space-nowrap" rowSpan={1} colSpan={1}>
                <span
                  style={{
                    color: _orders["color"] ? "#747D7D":"#1E2121",
                    background: _orders["color"]
                      ? `${_orders["color"]}33`
                      : "#ebecf0",
                  }}
                  className="tableStatus"
                >
                  {" "}
                  {_orders["status"] ?? ""}
                </span>
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["deliveryDate"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["deliveryDay"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                <input
                  type={`checkbox`}
                  defaultChecked={_orders["deliveryDateMoved"] ?? false}
                />
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                <input
                  type={`checkbox`}
                  defaultChecked={_orders["movedLate"] ?? false}
                />
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["deadLineDateTime"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["confirmationID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["vendorID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["departmentStoreID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders[""] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["breakerID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["linesnew"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["piecesNew"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["agConfirmationID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["dateTimeIn"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["serialNumber"] ?? ""}
              </td>
              {/*               <td rowSpan={1} colSpan={1}>
                <input
                  type={`checkbox`}
                  defaultChecked={_orders["blindOrder"] ?? false}
                />
              </td> */}
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["userID"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["whoRelease"] ?? ""}
              </td>
              <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders["sentDateTime"] ?? ""}
              </td>
              {/*               <td rowSpan={1} colSpan={1}>
                {" "}
                {_orders[""] ?? ""}
              </td> */}
            </tr>
          </>
        );
      });
  };

  return (
    <>
      <div className="p-3">
        {/*         <div className="breadcrumbContainer">
          <ul className="breadcrumbTabs">
            {tabsList.map((_tab, _tabIdx) => (
              <li
                key={`breadcrumbTabItems${_tabIdx}`}
                onClick={() => setSelectedTab(_tab.key)}
                className={`navlink ${
                  selectedTab === _tab.key ? "active" : ""
                }`}
              >
                <span>{_tab.label}</span>
              </li>
            ))}
          </ul>
          <div className="breadcrumbButtons">
            <div
              className="txt-btn"
              data-bs-toggle="collapse"
              data-bs-target="#filtersCollapse"
              aria-expanded="false"
              aria-controls="filtersCollapse"
            >
              More Filters <Icon icon="mdi:caret-down" />
            </div>
          </div>
        </div> */}
        {/*        <div className=" row m-0">
          <div className="collapse pt-3" id="filtersCollapse">
            <div className="bg-container row m-0 p-3">
              <div className="col-8 d-flex flex-wrap border-end">
                <div className="filterPills active">Orders</div>
                <div className="filterPills">Associated</div>
                <div className="filterPills">Detail</div>
                <div className="filterPills">Refresh</div>
                <div className="filterPills">In Date - Delivery Date</div>
                <div className="filterPills">A</div>
                <div className="filterPills">B</div>
                <div className="filterPills">C</div>
                <div className="filterPills">Issues</div>
                <div className="filterPills">Not Built</div>
                <div className="filterPills">Waiting</div>
                <div className="filterPills">Not Build All</div>
                <div className="filterPills">Test</div>
                <div className="filterPills">Mov</div>
                <div className="txt-btn mx-4">Clear</div>
              </div>
              <div className="col-4 row m-0">
                <div className="filterLinks col-6">
                  UPC - Item Routing Table{" "}
                  <Icon icon="material-symbols:arrow-right-alt-rounded" />
                </div>
                <div className="filterLinks col-6">
                  Delivery Schedule{" "}
                  <Icon icon="material-symbols:arrow-right-alt-rounded" />
                </div>
                <div className="filterLinks col-6">
                  Delivery Calendar{" "}
                  <Icon icon="material-symbols:arrow-right-alt-rounded" />
                </div>
                <div className="filterLinks col-6">
                  Store Settings{" "}
                  <Icon icon="material-symbols:arrow-right-alt-rounded" />
                </div>
                <div className="filterLinks col-6">
                  Load Setup{" "}
                  <Icon icon="material-symbols:arrow-right-alt-rounded" />
                </div>
                <div className="filterLinks col-6">
                  Warning Quantity Setup{" "}
                  <Icon icon="material-symbols:arrow-right-alt-rounded" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className=" row m-0 mt-3">
          <div className="col-6">
            <DatePicker
              oneTap
              value={
                ordersRequest._startDate
                  ? new Date(ordersRequest._startDate)
                  : new Date()
              }
              onChange={(value) => {
                //console.log("Date Value - ", value);
                const selectedDate = value ?? moment().toString();
                const dateFmtString = moment(selectedDate).format("YYYY-MM-DD");
                handleOrderRequest("startDate", dateFmtString);
              }}
            />
            {/*             <Button
              appearance="primary"
              className={`ms-2`}
              onClick={() => {
                GetOrdersList();
              }}
            >
              Refresh
            </Button> */}
            <Button
              appearance="primary"
              color="red"
              className={`ms-2`}
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                setOrdersRequest(
                  new OrdersRequestFailed(moment().format("YYYY-MM-DD"))
                );
                const targetInnerText: string | undefined = (
                  event.target as HTMLButtonElement | HTMLDivElement
                ).innerText;
                setActiveButton(targetInnerText);
              }}
            >
              Failed
            </Button>
            <Button
              appearance="primary"
              className={`ms-2`}
              color="green"
              onClick={(event) => {
                setOrdersRequest(
                  new OrdersRequestProcessed(moment().format("YYYY-MM-DD"))
                );
                const targetInnerText: string | undefined = (
                  event.target as HTMLButtonElement | HTMLDivElement
                ).innerText;
                setActiveButton(targetInnerText);
              }}
            >
              Processed
            </Button>
            <Button
              appearance="primary"
              className={`ms-2`}
              onClick={(event) => {
                setOrdersRequest(
                  new OrdersRequestWaiting(moment().format("YYYY-MM-DD"))
                );
                const targetInnerText: string | undefined = (
                  event.target as HTMLButtonElement | HTMLDivElement
                ).innerText;
                setActiveButton(targetInnerText);
              }}
            >
              Waiting
            </Button>
            <Button
              appearance="primary"
              className={`ms-2`}
              onClick={(event) => {
                setOrdersRequest(
                  new OrdersRequestNotBuilt(moment().format("YYYY-MM-DD"))
                );
                const targetInnerText: string | undefined = (
                  event.target as HTMLButtonElement | HTMLDivElement
                ).innerText;
                setActiveButton(targetInnerText);
              }}
            >
              Not Built
            </Button>
          </div>
        </div>
        <div className="tableContainer">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                {/*                 <th rowSpan={1} colSpan={1}>
                  {" "}
                  Mark{" "}
                </th> */}
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Store{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Type{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Status{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Delivery{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Day{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Moved{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Late{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Deadline{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Conf #{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Vendor{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Department{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Inv{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Brkr{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Lines{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Pieces{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  AG Conf #{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Received{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Serial{" "}
                </th>
                {/*                 <th rowSpan={1} colSpan={1}>
                  {" "}
                  Blind{" "}
                </th> */}
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  User{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Who Release{" "}
                </th>
                <th rowSpan={1} colSpan={1}>
                  {" "}
                  Sent{" "}
                </th>
                {/*                 <th rowSpan={1} colSpan={1}>
                  {" "}
                  ACCA{" "}
                </th> */}
              </tr>
            </thead>
            <tbody>{RenderOrderRows()}</tbody>
          </table>
        </div>
        <div className="paginationContainer">
          <div>
            Rows per page:{" "}
            <select
              value={paginationFilters.currentPageSize ?? 10}
              onChange={(event) => {
                const selectedValue = isNaN(Number(event.target.value))
                  ? 5
                  : Number(event.target.value);
                handlePaginationChange("currentPageSize", selectedValue);
              }}
            >
              <option value={1}>1</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
            </select>
          </div>
          <div className="recordCount">
            <p>
              Showing {paginationFilters.showingRecords ?? 0} of{" "}
              {paginationFilters.totalRecords ?? 0} entries
            </p>
          </div>
          <nav aria-label="Page navigation ">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={paginationFilters.totalPageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              disabledClassName={`disabled`}
              previousClassName={`previous`}
              nextClassName={`next`}
              pageLinkClassName={`page-link`}
              pageClassName={`page-item`}
              previousLinkClassName={`page-link`}
              nextLinkClassName={`page-link`}
              breakClassName={`page-item`}
              breakLinkClassName={`page-link`}
              renderOnZeroPageCount={null}
              onPageChange={(e) => {
                //console.log("Page Change event - ", e.selected + 1);
                handlePaginationChange("currentPage", e.selected);
              }}
              containerClassName={`pagination`}
              activeClassName={`active`}
            />
          </nav>
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;
