/* eslint-disable react-hooks/exhaustive-deps */
import TableComponent from "../../../components/common/tableComponent";
import FilteredTable from "./ViewOrderFilteredTable";
import FilteredTableSecond from "./ViewOrderFilteredTableSecond";
import * as AuthService from "../../../services/authService";
import { useEffect, useState } from "react";
import * as SettingsServices from "../../../services/settingsServices";
import * as OrdersServices from "../../../services/orderService";
import * as UMSService from "../../../services/umsService";
import { ViewOrderByDepartmentConstants } from "./ViewOrderByDepartmentConstants";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import { addDays } from "date-fns";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Button, Dropdown, Input, Modal, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import Constants from "../../.././utils/constants";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

const ViewOrderMain = (props: any) => {
  const { userContext } = props;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setSelectedRow1("");
  };
  const handleClose2 = () => {
    setOpen2(false);
    //setSelectedRowSecond1("");
  };
  const [confirmationIDDetailTable, setConfirmationIDDetailTable] =
    useState("");
  const [confirmationIDDetailTable2, setConfirmationIDDetailTable2] =
    useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataSecond, setSelectedDataSecond] = useState([]);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [filteredDepartment, setFilteredDepartment] = useState({});
  const [filteredConf, setFilteredConf] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [isFilteredSecond, setIsFilteredSecond] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow1, setSelectedRow1] = useState(null);
  const [selectedRowSecond, setSelectedRowSecond] = useState(null);
  const [selectedRow1Second, setSelectedRowSecond1] = useState(null);
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeName, setStoreName] = useState([]);
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState("");
  const [allVendorData, setAllVendorData] = useState("");
  const [allFilteredData, setAllFilteredData] = useState([]);
  const [totalAllData, setTotalAllData] = useState({});

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
    { groupStore_uid: "3", groupStore_desc: "MDI" },
  ];
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  const [IconFirst, setIconFirst] = useState("order_Type");
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), 0));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), 0));
  const [allDepartmentData, setAllDepartmentData] = useState([]);
  const [allDepartmentDetailData, setAllDepartmentDetailData] = useState([]);
  const [allDepartmentConfData, setAllDepartmentConfData] = useState([]);
  useEffect(() => {
    getDepartmentData(
      userContext && userContext.store ? userContext.store : userStore,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD"),
      vendorID ? vendorID : allVendorData
    );
  }, [allVendorData, vendorID, userStore, fromDate, toDate, userContext]);

  useEffect(() => {
    getDepartmentDetailData(
      userContext && userContext.store ? userContext.store : userStore,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD"),
      vendorID ? vendorID : allVendorData,
      selectedRow1 && selectedRow1.department
    );
  }, [
    allVendorData,
    vendorID,
    userStore,
    fromDate,
    toDate,
    selectedRow1,
    userContext,
  ]);
  useEffect(() => {
    setAllFilteredData(allDepartmentConfData);
  }, [allDepartmentConfData]);

  const getDepartmentData = async (store_ID, fromDate, toDate, vendor_ID) => {
    const { handleSpinner, userContext = {} } = props;

    // store_ID = userContext.store ? userContext.store : store_ID;

    handleSpinner && handleSpinner(true);

    OrdersServices.GetOrdersMain(store_ID, fromDate, toDate, vendor_ID)
      .then((result) => {
        result.viewOrdersByDepartment_M =
          result.viewOrdersByDepartment_M.filter(
            (item) => item.department !== ""
          );
        result.viewOrdersByDepartment_M.map(
          (item) => (item.department = item.department.trim())
        );

        result.viewOrdersByDepartment_M.map(
          (item) => (item.qty_Reg_Ord = Number(item.qty_Reg_Ord))
        );
        result.viewOrdersByDepartment_M.map(
          (item) => (item.qty_ADV_Ord = Number(item.qty_ADV_Ord))
        );
        setTotalAllData(calculateTotals(result.viewOrdersByDepartment_M));

        setAllDepartmentData(result.viewOrdersByDepartment_M);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  // Second Layer for Department data
  const getDepartmentDetailData = async (
    store_ID,
    fromDate,
    End_Date,
    vendor_ID,
    department_ID
  ) => {
    const { handleSpinner, userContext = {} } = props;

    department_ID && handleSpinner && handleSpinner(true);

    department_ID &&
      OrdersServices.GetOrdersForDepartment(
        store_ID,
        fromDate,
        End_Date,
        vendor_ID,
        department_ID
      )
        .then((result) => {
          setAllDepartmentDetailData(result.viewOrdersByConfirmation_M);

          setOpen(true);
          // setSelectedRow1("");
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
  };

  // Third Layer for Department data
  const getConfData = async (
    store_ID,
    fromDate,
    End_Date,
    vendor_ID,
    department_ID,
    ConfID
  ) => {
    const { handleSpinner, userContext = {} } = props;

    (department_ID || ConfID) && handleSpinner && handleSpinner(true);

    (department_ID || ConfID) &&
      OrdersServices.GetOrdersForConfID(
        store_ID,
        fromDate,
        End_Date,
        vendor_ID,
        department_ID,
        ConfID
      )
        .then((result) => {
          setAllDepartmentConfData(result.viewOrdersDetails_M);
          setOpen2(true);
          //setSelectedRowSecond1("");
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
  };

  const getUniqueTable = (dataset, value) => {
    if (dataset.length > 0) {
      var uniqueDepartments = new Set(dataset.map((obj) => obj[value]));
      var uniqueDepartmentsString = [...uniqueDepartments].join(",");
      return uniqueDepartmentsString;
    }
  };
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { store, groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );

          function sortByVendor(array) {
            return array.sort((a, b) => {
              const vendor_A = a.vendor_Name.toUpperCase();
              const vendor_B = b.vendor_Name.toUpperCase();

              if (vendor_A < vendor_B) {
                return -1;
              } else if (vendor_A > vendor_B) {
                return 1;
              } else {
                return 0;
              }
            });
          }
          const vendorIDs = uniqueVendorData
            .map((vendor) => vendor.vendor_ID.toString())
            .join(",");
          setAllVendorData(vendorIDs);
          sortByVendor(uniqueVendorData);
          setAllVendorName(uniqueVendorData);
          //setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          function sortByVendor(array) {
            return array.sort((a, b) => {
              const vendor_A = a.vendor_Name.toUpperCase();
              const vendor_B = b.vendor_Name.toUpperCase();

              if (vendor_A < vendor_B) {
                return -1;
              } else if (vendor_A > vendor_B) {
                return 1;
              } else {
                return 0;
              }
            });
          }
          sortByVendor(uniqueVendorData);
          //   uniqueVendorData.sort((a, b) => {
          //     // get first item for "AWG Memphis"
          //     if (a.vendor_Name === "AWG Memphis") return -1;
          //     if (b.vendor_Name === "AWG Memphis") return 1;

          //     return 0;
          //   });
          const vendorIDs = uniqueVendorData
            .map((vendor) => vendor.vendor_ID.toString())
            .join(",");
          setAllVendorData(vendorIDs);
          setAllVendorName(uniqueVendorData);
          //setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  useEffect(() => {
    if (userContext && !userContext.store) {
      SettingsServices.getVendorStoreName(userStore, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          const uniqueVendor = new Set();

          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          const vendorIDs = uniqueVendorData
            .map((vendor) => vendor.vendor_ID.toString())
            .join(",");
          setAllVendorData(vendorIDs);
          setAllVendorName(uniqueVendorData);
          setAllVendorName(uniqueVendorData);
          //setVendorID(uniqueVendorData[0].vendor_ID.toString());
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
    }
  }, [userStore, userContext]);

  const onOptionChangeVendor = (event) => {
    const VendorAndStore = event;
    setVendorID(VendorAndStore.toString());
    //setShowAllData(true);
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };

  const handleRowClick = (rowIndex) => {
    var newSelectedRows = [...selectedData];

    if (
      newSelectedRows.some(
        (item) => item.department === allDepartmentData[rowIndex].department
      )
    ) {
      newSelectedRows = newSelectedRows.filter(
        (item) => item.department !== allDepartmentData[rowIndex].department
      );
    } else {
      newSelectedRows = newSelectedRows.concat(allDepartmentData[rowIndex]);
    }

    //setSelectedDataTotal(calculateTotals(newSelectedRows));
    //State'i güncelleyin

    // setFilteredDepartment(
    //   newSelectedRows.map((item) => {
    //     return item.department_ID;
    //   })
    // );
    setFilteredDepartment(
      newSelectedRows.map((item) => item.department.toString()).join(",")
    );
    if (newSelectedRows.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    setSelectedData(newSelectedRows);
  };

  const handleRowClickSecond = (rowIndex) => {
    var newSelectedRows = [...selectedDataSecond];
    if (
      newSelectedRows.some(
        (item) =>
          item.confirmation_ID ===
          allDepartmentDetailData[rowIndex].confirmation_ID
      )
    ) {
      newSelectedRows = newSelectedRows.filter(
        (item) =>
          item.confirmation_ID !==
          allDepartmentDetailData[rowIndex].confirmation_ID
      );
    } else {
      newSelectedRows = newSelectedRows.concat(
        allDepartmentDetailData[rowIndex]
      );
    }

    //setSelectedDataTotal(calculateTotals(newSelectedRows));
    //State'i güncelleyin

    // setFilteredDepartment(
    //   newSelectedRows.map((item) => {
    //     return item.department_ID;
    //   })
    // );
    setFilteredConf(
      newSelectedRows.map((item) => item.confirmation_ID.toString()).join(",")
    );
    if (newSelectedRows.length > 0) {
      setIsFilteredSecond(true);
    } else {
      setIsFilteredSecond(false);
    }

    setSelectedDataSecond(newSelectedRows);
  };
  const SecondLayerGetEyeData = (result) => {
    getConfData(
      userContext && userContext.store ? userContext.store : userStore,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD"),
      vendorID ? vendorID : allVendorData,
      selectedRow1 && selectedRow1.department,
      result && result.confirmation_ID
    );
  };

  // const filterList = (value) => {
  //   var regex = /^[a-zA-Z]+$/;
  //   if (value) {
  //     if (value.match(regex)) {
  //       setAllFilteredData(
  //         allDepartmentConfData.filter((item) =>
  //           item.description
  //             .toLowerCase()
  //             .includes(value.toString().toLowerCase())
  //         )
  //       );
  //     }
  //   } else {
  //     setAllFilteredData(allDepartmentConfData);
  //   }
  // };

  const filterList = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllFilteredData(
          allDepartmentConfData.filter((item) =>
            item.item_ID
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllFilteredData(
          allDepartmentConfData.filter((item) =>
            item.description
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllFilteredData(allDepartmentConfData);
    }
  };

  function calculateTotals(data) {
    let totalRegOrd = 0;
    let totalAdvOrd = 0;
    let totalItems = 0;
    let totalCases = 0;
    let totalTotal = 0;
    for (let entry of data) {
      totalRegOrd += Number(entry.qty_Reg_Ord) || 0;
      totalAdvOrd += Number(entry.qty_ADV_Ord) || 0;
      totalItems += Number(entry.items_Sent) || 0;
      totalCases += Number(entry.qty_Sent) || 0;
      totalTotal += Number(entry.total) || 0;
    }

    return {
      qty_Reg_Ord: totalRegOrd,
      qty_ADV_Ord: totalAdvOrd,
      items_Sent: totalItems,
      qty_Sent: totalCases,
      total: totalTotal,
    };
  }
  return (
    <div>
      <div className="d-flex justify-content-start align-items-center">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          onOptionChangeVendor={onOptionChangeVendor}
          allVendorName={allVendorName}
          isVendor={true}
          isCalendar={true}
        />
        <div style={{ height: "36px" }} className="col-3 p-2 reportDescItem">
          <>
            <div className="label">
              {isFiltered ? (
                <div
                  style={{ height: "36px" }}
                  className="col-1 p-0 reportDescItem"
                >
                  <>
                    <div className="label">
                      <Button
                        onClick={(e) => {
                          getDepartmentDetailData(
                            userContext && userContext.store
                              ? userContext.store
                              : userStore,
                            moment(fromDate).format("YYYY-MM-DD"),
                            moment(toDate).format("YYYY-MM-DD"),
                            vendorID ? vendorID : allVendorData,
                            filteredDepartment
                          );
                          setIconFirst("");
                        }}
                        appearance="primary"
                        className="m-0"
                      >
                        Filter
                      </Button>
                    </div>
                  </>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </>
        </div>
      </div>

      <FilteredTable
        isFromModal={false}
        tableDetails={
          ViewOrderByDepartmentConstants["ViewOrderByDepartmentConstants"]
        }
        setConfirmationIDTable={setConfirmationIDDetailTable}
        reportList={allDepartmentData}
        setOpen4={setOpen}
        setSelectedData={setSelectedData}
        handleRowClick={handleRowClick}
        selectedData={selectedData}
        setSelectedRow={setSelectedRow}
        setSelectedRow1={setSelectedRow1}
        iconColumn={"department"}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={totalAllData}
        isTotalRowRequired={true}
        showAll={false}
      />

      {/* Detail Page For Department */}
      {/* selectedRow1 */}
      {allDepartmentDetailData && (
        <Modal size={"full"} open={open} onClose={handleClose}>
          <Modal.Header style={{ overflow: "scroll", maxHeight: "200px" }}>
            <div>
              <Modal.Title>
                <span
                  className="mx-2 filter"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon icon="ep:back" />
                </span>
                <div style={{ textAlign: "center" }}>
                  View Order Detail By Department
                </div>
              </Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Store ID</p>
                    <p className="m-0">
                      {userContext && userContext.store
                        ? userContext.store
                        : userStore}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Vendor</p>
                    <p className="m-0">
                      {/* {allDepartmentDetailData && 
                        ? allDepartmentDetailData[0].vendor
                        : null} */}

                      {getUniqueTable(allDepartmentDetailData, "vendor")}
                    </p>
                  </div>
                </>
              </div>

              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Department</p>
                    <p className="m-0">
                      {/* {allDepartmentDetailData && allDepartmentDetailData[0]
                        ? allDepartmentDetailData[0].department
                        : null} */}
                      {getUniqueTable(allDepartmentDetailData, "department")}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">User ID</p>
                    <p className="m-0">
                      {/* {allDepartmentDetailData && allDepartmentDetailData[0]
                        ? allDepartmentDetailData[0].department
                        : null} */}
                      {getUniqueTable(allDepartmentDetailData, "user_ID")}
                    </p>
                  </div>
                </>
              </div>

              <div className="col-1 p-2 reportDescItem">
                {" "}
                <Dropdown
                  renderToggle={(_props, _ref) => (
                    <div className="filter" ref={_ref} {..._props}>
                      <Icon icon="pajamas:export" /> Export
                    </div>
                  )}
                  className="ms-2 me-0 button-fixed"
                  appearance="link"
                  placement="bottomEnd"
                >
                  <Dropdown.Item
                    icon={<FileDownloadIcon />}
                    onClick={() => {
                      exportToExcel(allDepartmentDetailData, `Order View`);
                    }}
                  >
                    Export to Excel
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="label">
                    {isFiltered && isFilteredSecond ? (
                      // <div
                      //   className="mx-2 filter1"
                      //   onClick={(e) => {
                      //     getConfData(
                      //       userContext && userContext.store
                      //         ? userContext.store
                      //         : userStore,
                      //       moment(fromDate).format("YYYY-MM-DD"),
                      //       moment(toDate).format("YYYY-MM-DD"),
                      //       vendorID ? vendorID : allVendorData,
                      //       filteredDepartment,
                      //       filteredConf
                      //     );
                      //   }}
                      // >
                      //   <Icon icon="material-symbols:filter-alt-outline-sharp" />
                      //   Show Filtered Data2
                      // </div>

                      <div className="col-1 p-0 reportDescItem">
                        <>
                          <div className="label">
                            <Button
                              onClick={(e) => {
                                getConfData(
                                  userContext && userContext.store
                                    ? userContext.store
                                    : userStore,
                                  moment(fromDate).format("YYYY-MM-DD"),
                                  moment(toDate).format("YYYY-MM-DD"),
                                  vendorID ? vendorID : allVendorData,
                                  filteredDepartment,
                                  filteredConf
                                );
                              }}
                              appearance="primary"
                              className="m-0"
                            >
                              Filter2
                            </Button>
                          </div>
                        </>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <FilteredTableSecond
              userContext={userContext}
              isFromModal={false}
              tableDetails={
                ViewOrderByDepartmentConstants[
                  "ViewOrderByDepartmentDetailConstants"
                ]
              }
              setConfirmationIDTable={setConfirmationIDDetailTable2}
              reportList={allDepartmentDetailData}
              setOpen={setOpen2}
              setSelectedData={setSelectedDataSecond}
              handleRowClick={handleRowClickSecond}
              selectedData={selectedDataSecond}
              setSelectedRow={setSelectedRowSecond}
              setSelectedRow1={setSelectedRowSecond1}
              SecondLayerGetEyeData={SecondLayerGetEyeData}
              iconColumn={IconFirst ? IconFirst : ""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{}}
              isTotalRowRequired={false}
            />
          </Modal.Body>
        </Modal>
      )}

      {allDepartmentConfData && (
        <Modal size={"full"} open={open2} onClose={handleClose2}>
          <Modal.Header style={{ overflow: "scroll", maxHeight: "200px" }}>
            <span
              className="mx-2 filter"
              onClick={() => {
                setOpen2(false);
              }}
            >
              <Icon icon="ep:back" />
            </span>
            <div style={{ textAlign: "center" }}>
              <Modal.Title>Show Filtered Data By Confirmation ID</Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Store ID</p>
                    <p className="m-0">
                      {userContext && userContext.store
                        ? userContext.store
                        : userStore}
                    </p>
                  </div>
                </>
              </div>
              {/* {(filteredConf || selectedRow1Second) && (
                <div className="col-3 p-2 reportDescItem">
                  <>
                    <div className="icon">
                      <Icon icon="material-symbols:store-outline" />
                    </div>
                    <div className="label">
                      <p className="m-0">Conf ID</p>
                      <p className="m-0">
                        {selectedRow1Second
                          ? selectedRow1Second &&
                            selectedRow1Second.confirmation_ID
                          : filteredConf
                          ? filteredConf && filteredConf
                          : ""}
                      </p>
                    </div>
                  </>
                </div>
              )} */}

              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Vendor</p>
                    <p className="m-0">
                      {getUniqueTable(allFilteredData, "vendor")}
                    </p>
                  </div>
                </>
              </div>

              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Department</p>
                    <p className="m-0">
                      {getUniqueTable(allFilteredData, "department")}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Conf ID</p>
                    <p className="m-0">
                      {getUniqueTable(allFilteredData, "confirmation_ID")}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">User ID</p>
                    <p className="m-0">
                      {/* {allDepartmentDetailData && allDepartmentDetailData[0]
                        ? allDepartmentDetailData[0].department
                        : null} */}
                      {getUniqueTable(allFilteredData, "user_ID")}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">
                    <InputGroup
                      onChange={(event) => {
                        filterList(event.target.value);
                      }}
                      style={{
                        width: 300,
                        height: 40,
                        fontWeight: 800,
                      }}
                    >
                      <Input placeholder="Filter Description and Item ID" />
                      <InputGroup.Button>
                        <SearchIcon />
                      </InputGroup.Button>
                    </InputGroup>
                  </p>
                </div>
              </div>

              <div className="col-1 p-2 reportDescItem">
                {" "}
                <Dropdown
                  renderToggle={(_props, _ref) => (
                    <div className="filter" ref={_ref} {..._props}>
                      <Icon icon="pajamas:export" /> Export
                    </div>
                  )}
                  className="ms-2 me-0 button-fixed"
                  appearance="link"
                  placement="bottomEnd"
                >
                  <Dropdown.Item
                    icon={<FileDownloadIcon />}
                    onClick={() => {
                      exportToExcel(allFilteredData, `Order View`);
                    }}
                  >
                    Export to Excel
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <TableComponent
              userContext={userContext}
              isFromModal={false}
              tableDetails={
                ViewOrderByDepartmentConstants[
                  "ViewOrderByDepartmentDetailbyDeptConstants"
                ]
              }
              reportList={allFilteredData}
              setOpen={() => {}}
              iconColumn={""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{}}
              isTotalRowRequired={false}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ViewOrderMain;
