import { useEffect, useState } from "react";
import * as OrdersServices from "../../../services/orderService";
import * as SettingsServices from "../../../services/settingsServices";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";
import Constants from "../../.././utils/constants";
import { Button, Dropdown, Input, Tooltip, Whisper, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { Icon } from "@iconify/react";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { addDays } from "date-fns";
import TableComponent from "../../../components/common/tableComponent";
import { BadItemsConstants } from "./BadItemsConstants";

const BadItemsMain = (props: any) => {
  const { userContext } = props;
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), 0));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), 0));
  const [inputValue, setInputValue] = useState("");

  const [badItemsList, setBadItemsList] = useState([]);
  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");
  const [storeName, setStoreName] = useState([]);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  //   useEffect(() => {
  //     getBadItems(
  //       userStore,
  //       moment(fromDate).format("YYYY-MM-DD"),
  //       moment(toDate).format("YYYY-MM-DD"),
  //       inputValue
  //     );
  //   }, [userContext, userStore, fromDate, toDate]);

  useEffect(() => {
    if (
      userContext &&
      userContext.store === 0 &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.store === 0 &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.store === 0 &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [userContext, awgOrGotSelect]);
  // Get All Data for Bad Items report
  const getBadItems = async (store_ID, Start_Date, EndDate, UpcItemNo) => {
    const { handleSpinner, userContext = {} } = props;
    handleSpinner && handleSpinner(true);
    OrdersServices.GetBadItems(store_ID, Start_Date, EndDate, UpcItemNo)
      .then((result) => {
        result.map(
          (item) =>
            (item.delivery_Date = moment(item.delivery_Date).format(
              "MM-DD-YYYY"
            ))
        );
        setBadItemsList(result);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { store, groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  const executeReport = () => {
    inputValue &&
      getBadItems(
        userContext.store ? userContext.store : userStore,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD"),
        inputValue
      );
  };

  return (
    <>
      <div className="BadItemsHeader">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          isVendor={false}
          isCalendar={true}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        <div className="bad-Items-Input">
          <InputGroup inside style={{ width: "300px" }}>
            <Input
              onChange={(event) => setInputValue(event)}
              placeholder="UPC or Item ID (Seperate Comma)"
            />
            <InputGroup.Addon>
              <Whisper
                placement="top"
                speaker={<Tooltip> UPC or Item ID ()</Tooltip>}
              >
                <SearchIcon />
              </Whisper>
            </InputGroup.Addon>
          </InputGroup>
        </div>
        <div>
          <Button onClick={() => executeReport()} appearance="primary">
            Execute Report
          </Button>{" "}
        </div>
      </div>

      <div>
        <div className="d-flex justify-content-end align-items-center">
          {" "}
          <Dropdown
            renderToggle={(_props, _ref) => (
              <div className="filter" ref={_ref} {..._props}>
                <Icon icon="pajamas:export" /> Export
              </div>
            )}
            className="ms-2 me-0 button-fixed"
            appearance="link"
            placement="bottomEnd"
          >
            <Dropdown.Item
              icon={<FileDownloadIcon />}
              onClick={() => {
                exportToExcel(badItemsList, `Bad Items Report`);
              }}
            >
              Export to Excel
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>

      <TableComponent
        userContext={userContext}
        isFromModal={false}
        tableDetails={BadItemsConstants["BadItemList"]}
        reportList={badItemsList}
        iconColumn={""}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </>
  );
};

export default BadItemsMain;
