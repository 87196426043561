/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as SettingsServices from "../../../services/settingsServices";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import Constants from "../../.././utils/constants";
//import TableComponent from "../../common/tableComponent";
import LastDayToSaveTable from "./LastDayToSaveTable";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportLDTSConstants } from "./ReportLastDayToSaveConstants";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { addDays } from "date-fns";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";
import * as ReportService from "../../../services/reportServices";
import LastDayToSaveItemDetail from "./ItemDetail/LastDayToSaveItemDetail";
const ReportLastDayToSave = (props: any) => {
  // Calendar

  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), 0));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), 0));
  // const [toDate, setToDate] = useState<Date>(new Date());
  const [storeName, setStoreName] = useState([]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState(storeID[0]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[0]);
  const [lastDayToSaveData, setLastDayToSaveData] = useState([]);
  const [totalRowMain, setTotalRowMain] = useState({});
  const [openDetail, setOpenDetail] = useState(false);

  const [itemDetailRow, setItemDetailRow] = useState({});
  const [isDetail, setIsDetail] = useState(false);

  const { userContext = {} } = props;

  useEffect(() => {
    getReportData();
  }, [
    userStore,
    storeNameHeader,
    fromDate,
    toDate,
    userContext,
    vendorID,
    openDetail,
  ]);
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID } = userContext;
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        result.records.sort((a, b) => {
          const Store_A = a.store_ID;
          const Store_B = b.store_ID;

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        if (storesArray.length >= 1) {
          result.records = result.records.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
        }
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        const uniqueVendor = new Set();
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });
        result.records.forEach((item) => {
          const { vendor_ID, vendor_Name } = item;
          uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
        });
        var uniqueVendorData = Array.from(uniqueVendor).map((store) =>
          JSON.parse(store as string)
        );

        uniqueVendorData.sort((a, b) => {
          const Store_A = a.vendor_Name;
          const Store_B = b.vendor_Name;

          if (Store_A < Store_B || Store_A.includes("AWG KC")) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        uniqueVendorData = uniqueVendorData.filter((item) => {
          return (
            item.vendor_Name.includes("AWG") || item.vendor_Name.includes("VMC")
          );
        });
        setAllVendorName(uniqueVendorData);
        setVendorID(uniqueVendorData[0]?.vendor_ID);
        setStoreName(setArrayName);
        setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  function TotalRowMain(objectsArray) {
    let total = {
      adv_Ord_Peding_Qty: 0,
      adv_Ord_Qty: 0,
      adv_Ord_Total_Qty: 0,
      f_Cost_0: 0,
      saving_amount: 0,
      cost_S_W_1: 0,
      cost_S_W_2: 0,
      cost_S_W_3: 0,
      cost_S_W_4: 0,
    };

    objectsArray.forEach((obj) => {
      total.adv_Ord_Peding_Qty += obj.adv_Ord_Peding_Qty || 0;
      total.adv_Ord_Qty += obj.adv_Ord_Qty || 0;
      total.adv_Ord_Total_Qty += obj.adv_Ord_Total_Qty || 0;
      total.f_Cost_0 += obj.f_Cost_0 || 0;
      total.saving_amount += obj.saving_amount || 0;
      total.cost_S_W_1 += obj.cost_S_W_1 || 0;
      total.cost_S_W_2 += obj.cost_S_W_2 || 0;
      total.cost_S_W_3 += obj.cost_S_W_3 || 0;
      total.cost_S_W_4 += obj.cost_S_W_4 || 0;
    });

    return setTotalRowMain(total);
  }

  const getReportData = async () => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      //   ReportService.getLastDayToSave()
      //     .then((result) => {})
      //     .catch((err) => {
      //       console.error("Error: Get Orders - ", err);
      //       // toast.error("Error Loading Orders");
      //     })
      //     .finally(() => {
      //       handleSpinner && handleSpinner(false);
      //     });
    } else if (groupTypeID && vendorID && fromDate) {
      handleSpinner && handleSpinner(true);
      ReportService.getLastDayToSave(
        vendorID.toString(),
        groupTypeID,
        moment(fromDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          TotalRowMain(result.records);
          modifyReportData(result.records);
          setLastDayToSaveData(result.records);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const modifyReportData = (data) => {
    data.map((item) => {
      item.cost_Change_Percent === 0
        ? (item.cost_Change_Percent = "")
        : item.cost_Change_Percent;

      item.adv_Ord_Peding_Qty === 0
        ? (item.adv_Ord_Peding_Qty = "")
        : item.adv_Ord_Peding_Qty;

      item.adv_Ord_Qty === 0 ? (item.adv_Ord_Qty = "") : item.adv_Ord_Qty;

      item.adv_Ord_Total_Qty === 0
        ? (item.adv_Ord_Total_Qty = "")
        : item.adv_Ord_Total_Qty;

      item.cases_in_WH === 0 ? (item.cases_in_WH = "") : item.cases_in_WH;

      item.cases_Pending_WH === 0
        ? (item.cases_Pending_WH = "")
        : item.cases_Pending_WH;

      item.pB_Diff === 0 ? (item.pB_Diff = "") : item.pB_Diff;

      item.ad_Future === 0 ? (item.ad_Future = "") : item.ad_Future;

      item.avg_13_Wks_Movement === 0
        ? (item.avg_13_Wks_Movement = "")
        : item.avg_13_Wks_Movement;

      item.avg_13_Wks_Purch === 0
        ? (item.avg_13_Wks_Purch = "")
        : item.avg_13_Wks_Purch;

      return item;
    });
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Filters Drawer

  //#endregion

  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getStoreName(userContext.store);
    }
  }, [userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const onOptionChangeVendor = (event) => {
    const VendorAndStore = event;
    setVendorID(VendorAndStore);
    //setShowAllData(true);
  };

  const GetItemDetail = (value) => {
    setItemDetailRow(value);
    setIsDetail(true);
  };
  return (
    <>
      <div className="d-flex  justify-content-start align-items-center">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          isVendor={true}
          isPlacementGroup={true}
          allVendorName={allVendorName}
          onOptionChangeVendor={onOptionChangeVendor}
          isCalendar={true}
          oldCalendar={true}
        />

        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(
                lastDayToSaveData,
                `Last Day To Save Report ${
                  userContext && userContext.store
                    ? userContext.store
                    : userStore
                }`
              );
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>
      <LastDayToSaveTable
        isFromModal={false}
        tableDetails={ReportLDTSConstants["LastDayToSaveMain"]}
        reportList={lastDayToSaveData}
        iconColumn={"description"}
        onTableRowClick={(value) => {
          GetItemDetail(value);
        }}
        reportType={""}
        totalTableRow={totalRowMain}
        isTotalRowRequired={true}
      />
      {isDetail && (
        <LastDayToSaveItemDetail
          userContext={userContext}
          isDetail={isDetail}
          setOpenDetail={setOpenDetail}
          openDetail={openDetail}
          setIsDetail={setIsDetail}
          vendorID={vendorID}
          groupTypeID={groupTypeID}
          fromDate={fromDate}
          itemDetailRow={itemDetailRow}
        />
      )}
    </>
  );
};

export default ReportLastDayToSave;
