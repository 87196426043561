/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  CheckPicker,
  Dropdown,
  IconButton,
  PickerHandle,
  SelectPicker,
  InputPicker,
} from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../services/reportServices";
import * as SettingsServices from "../../../services/settingsServices";
import * as AuthService from "../../../services/authService";
import Constants from "../../.././utils/constants";
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportConstants } from "./reportGrindLogConstants";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import Calendar from "../../common/CalendarComponent";
import * as UMSService from "../../../services/umsService";

const ReportGrindLogComponent = (props: any) => {
  //#region Props
  const { handleSpinner, userContext = {} } = props;
  const { groupType } = userContext ?? {};
  //#endregion
  //#region Report API Filters
  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Detailed",
  });
  // Calendar
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));

  // Switch
  const [selectedReportType, setSelectedReportType] =
    useState("Grind Log Details");

  // Store List
  const [storeList, setStoreList] = useState([]);
  const [storeSelected, setStoreSelected] = useState(null);

  // No Data Found
  const [DisplayNoDatatlabel, setDisplayNoDatatlabel] = useState(false);
  const [isDataloaded, setIsDataloaded] = useState(false);
  //#endregion

  //#region Reports Tables Config
  const [listTableHeaders, setListTableHeaders] = useState([]);
  const [listTableTotals, setListTableTotals] = useState({});
  const [listTableReports, setListTableReports] = useState([]);
  //#endregion

  const [reportList, setReportList] = useState([]);
  const [reportMasterList, setReportMasterList] = useState([]);
  const [reportCaseList, setReportCaseList] = useState([]);
  const [reportMasterCaseList, setReportMasterCaseList] = useState([]);
  const [reportSummaryList, setReportSummaryList] = useState([]);
  const [reportMasterSummaryList, setReportMasterSummaryList] = useState([]);
  const [totalWeekTableRow, setTotalWeekTableRow] = useState({});
  //#region Filters
  const [columnFilters, setColumnFilters] = useState([]);
  const [filterCount, setFilterCount] = useState({});
  const [isfilterSelected, setFilterSelected] = useState(false);
  const [selectedColumnFilters, setSelectedColumnFilters] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(true);
  const picker = useRef<PickerHandle>();
  //#endregion

  ///////////////////////////////
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
    { groupStore_uid: "3", groupStore_desc: "MDI" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 3
    ) {
      GetGroupAndStoreByTypes("3", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2" || IDType === "3") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (userContext && userContext.roleName === "DM") {
      SettingsServices.getVendorStoreName(
        grouptypeID1,
        "GetJustStore",
        awgOrGotSelect
      )
        .then((result) => {
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            return setArrayID.push(item.store_ID);
          });
          result.records.filter((item) => {
            return setArrayName.push(item.store_Name);
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (awgOrGotSelect) {
      SettingsServices.getVendorStoreName(
        grouptypeID1,
        "GetJustStore",
        groupType
      )
        .then((result) => {
          if (groupType === 2 || groupType === 3) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            return setArrayID.push(item.store_ID);
          });
          result.records.filter((item) => {
            return setArrayName.push(item.store_Name);
          });

          setStoreName(setArrayName);
          //setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  //#region Vendor Store Name
  // const getVendorStoreName = async (grouptypeID1 = "") => {
  //   const { handleSpinner, userContext = {} } = props;
  //   const { groupType, user_ID, roleName } = userContext;
  //   handleSpinner && handleSpinner(true);
  //   if (awgOrGotSelect) {
  //     var storesArray = [];
  //     AuthService.getDMStores(user_ID)
  //       .then((result1) => {
  //         result1.records.map((item) => storesArray.push(item.stores));
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       });
  //     SettingsServices.getVendorStoreName(
  //       grouptypeID1,
  //       "GetJustStore",
  //       awgOrGotSelect
  //     )
  //       .then((result) => {
  //         result.records.sort((a, b) => {
  //           const Store_A = a.store_ID;
  //           const Store_B = b.store_ID;

  //           if (Store_A < Store_B) {
  //             return -1;
  //           } else if (Store_A > Store_B) {
  //             return 1;
  //           } else {
  //             return 0;
  //           }
  //         });
  //         if (storesArray.length >= 1) {
  //           result.records = result.records.filter((item) =>
  //             storesArray.includes(item.store_ID.toString())
  //           );
  //         }
  //         //console.log("StoreName: Result - ", result.records);
  //         var setArrayID = [];
  //         var setArrayName = [];
  //         result.records.filter((item) => {
  //           setArrayID.push(item.store_ID);
  //         });
  //         result.records.filter((item) => {
  //           setArrayName.push(item.store_Name);
  //         });

  //         setStoreName(setArrayName);
  //         setUserStore(setArrayID[0]);
  //         setStoreID(setArrayID);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   } else {
  //     SettingsServices.getVendorStoreName(
  //       grouptypeID1,
  //       "GetJustStore",
  //       groupType
  //     )
  //       .then((result) => {
  //         result.records.sort((a, b) => {
  //           const Store_A = a.store_ID;
  //           const Store_B = b.store_ID;

  //           if (Store_A < Store_B) {
  //             return -1;
  //           } else if (Store_A > Store_B) {
  //             return 1;
  //           } else {
  //             return 0;
  //           }
  //         });
  //         //console.log("StoreName: Result - ", result.records);
  //         var setArrayID = [];
  //         var setArrayName = [];
  //         result.records.filter((item) => {
  //           setArrayID.push(item.store_ID);
  //         });
  //         result.records.filter((item) => {
  //           setArrayName.push(item.store_Name);
  //         });

  //         setStoreName(setArrayName);
  //         setUserStore(setArrayID[0]);
  //         setStoreID(setArrayID);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   }
  // };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    if (storeID) {
      setUserStore(storeID[0]);
    }
  };
  const SelectAwgOrGot = (event) => {
    //console.log(event.target.value);
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
  };

  //////////////////////////////

  const tabsList: any[] = [
    { value: "1", label: "Detailed" },
    { value: "2", label: "Summary" },
  ];

  useEffect(() => {
    getStoresData();
  }, [
    props.userContext,
    storeNameHeader,
    userStore,
    fromDate,
    toDate,
    selectedTab,
  ]);

  useEffect(() => {
    if (
      props.userContext &&
      props.userContext.store &&
      props.userContext.store !== 0
    ) {
      getStoreName(props.userContext.store);
    }
  }, [props.userContext]);

  //#region Common Functions
  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
    //getReportData(selectedTab, startDate, endDate, storeSelected);
  };

  //#endregion

  window.addEventListener("error", (e) => {
    if (
      e.message ===
        "ResizeObserver loop completed with undelivered notifications." ||
      e.message === "ResizeObserver loop limit exceeded"
    ) {
      e.stopImmediatePropagation();
    }
  });

  useEffect(() => {
    calTotalWeekRowData(reportCaseList, ReportConstants["Grind Log Case"]);
  }, [reportCaseList]);

  useEffect(() => {
    handleDataChange();
    columnFiltersData();
  }, [
    reportMasterList,
    reportMasterCaseList,
    reportMasterSummaryList,
    selectedReportType,
  ]);

  const handleDataChange = () => {
    let headers = [];
    let data = [];
    let totals = {};
    headers =
      selectedTab.label === "Detailed"
        ? ReportConstants[selectedReportType]
        : ReportConstants[selectedTab.label];
    data =
      selectedTab.label === "Detailed"
        ? selectedReportType === "Grind Log Details"
          ? reportList
          : reportCaseList
        : reportSummaryList;
    totals =
      selectedTab.label === "Detailed" &&
      selectedReportType === "Grind Log Case"
        ? calTotalWeekRowData(reportCaseList, ReportConstants["Grind Log Case"])
        : {};
    setListTableReports(data);
    setListTableTotals(totals);
    setListTableHeaders(headers);
    setDisplayNoDatatlabel(isDataloaded && data.length === 0 ? true : false);
  };
  useEffect(() => {
    setIsCollapsed(false);
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  //#region GetData by Selected Tab
  const getReportData = (selectedTab, fromDate, toDate, storeSelected) => {
    if (selectedTab.value === "1") {
      getReportDetailedData(fromDate, toDate, storeSelected);
    } else {
      getReportSummaryData(fromDate, toDate);
    }
  };

  // useEffect(() => {
  //   getReportData(selectedTab, fromDate, toDate, storeSelected);
  // }, [fromDate, toDate]);
  //#endregion
  //#region Getting Report Data
  const getReportDetailedData = async (fromDate, toDate, storeId) => {
    const { handleSpinner, userContext } = props;
    const { store, roleName } = userContext;

    handleSpinner && handleSpinner(true);
    setIsDataloaded(false);
    setDisplayNoDatatlabel(false);
    var Grindlog;
    var GrindlogCase;
    try {
      if (userContext && store !== 0) {
        Grindlog = await ReportService.getReportGrindLog(
          store,
          moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD")
        );
        GrindlogCase = await ReportService.getReportGrindLogCase(
          store,
          moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD")
        );
      } else {
        Grindlog = await ReportService.getReportGrindLog(
          storeId,
          moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD")
        );
        GrindlogCase = await ReportService.getReportGrindLogCase(
          storeId,
          moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD")
        );
      }

      const GrindlogResponse = modifyReportData(Grindlog.details);
      const GrindlogCaseResponse = modifyReportData(GrindlogCase.case);
      setReportList(GrindlogResponse);
      setReportMasterList(GrindlogResponse);
      setReportCaseList(GrindlogCaseResponse);
      setReportMasterCaseList(GrindlogCaseResponse);
      setIsDataloaded(true);
    } catch (err) {
      console.error("Error: Get Reports - ", err);
    } finally {
      handleSpinner && handleSpinner(false);
    }
  };
  //#endregion
  //#region Getting Summary Report
  const getReportSummaryData = (fromDate, toDate) => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, groupTypeId, store } = userContext;
    handleSpinner && handleSpinner(true);
    setIsDataloaded(false);
    setDisplayNoDatatlabel(false);
    if (userContext && store === 0 && groupTypeId !== 0 && !userStore) {
      ReportService.getReportGrindLogSummary(
        groupTypeID ? groupTypeID : groupTypeId,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
          setReportMasterSummaryList(GrindlogSummaryResponse);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userContext && store !== 0) {
      ReportService.getReportGrindLogSummary(
        store,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
          setReportMasterSummaryList(GrindlogSummaryResponse);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userContext && userStore) {
      ReportService.getReportGrindLogSummary(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
          setReportMasterSummaryList(GrindlogSummaryResponse);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      ReportService.getReportGrindLogSummary(
        groupTypeId !== 0 ? groupTypeId : null,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
          setReportMasterSummaryList(GrindlogSummaryResponse);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  //#endregion

  //#region Get Stores
  const getStoresData = () => {
    handleSpinner && handleSpinner(true);
    const { groupType, groupTypeId, roleName } = props.userContext ?? {};
    if (groupType && groupTypeId !== 0 && !userStore && roleName !== "DM") {
      ReportService.getStoreListByGroup(groupTypeId)
        .then((reportData) => {
          const _storeList = (reportData.data ?? [])
            .map((item) => ({ label: item.storeName, value: item.storeID }))
            .sort((a, b) => {
              let x = a.value ? a.value : "";
              let y = b.value ? b.value : "";
              return x
                .toString()
                .localeCompare(y.toString(), undefined, { numeric: true });
            });

          setStoreList(_storeList);

          if (_storeList && _storeList.length > 0) {
            setStoreSelected(_storeList[0].value ?? null);
            getReportData(selectedTab, fromDate, toDate, _storeList[0].value);
          }
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
          handleSpinner && handleSpinner(false);
        });
    } else if (userStore) {
      setStoreSelected(groupTypeId ?? null);
      getReportData(selectedTab, fromDate, toDate, userStore);
    } else {
      setStoreSelected(groupTypeId ?? null);
      getReportData(selectedTab, fromDate, toDate, groupTypeId);
    }
  };
  //#endregion

  //#region Modifying Report Data
  const modifyReportData = (data) => {
    let modifiedData = data.map((item) => {
      item["FormatedDateTime"] =
        item.scan_Time &&
        item.scan_Time.trim() &&
        moment(item.scan_Time).format("MM-DD-YYYY hh:mm A");
      item["FormatedProductDate"] =
        item.prod_Date &&
        item.prod_Date.trim() &&
        moment(item.prod_Date).format("MM-DD-YYYY");
      item["ProdWeight"] =
        item.out_Weight &&
        item.out_Weight.trim() &&
        Math.round(Number(item.out_Weight)).toFixed(0);
      item["grinder_N"] = item.grinder_N !== 0 ? "#" + item.grinder_N : "";
      item["lug_N"] = item.lug_N !== 0 ? "#" + item.lug_N : "";
      item["Badge_Type"] =
        item.date_Scanned_Last && item.date_Scanned_Last !== null
          ? " Yes"
          : "No";
      //item["Badge_Type_Days"] =  item.date_Scanned_Last !==null ? "With Badge" :"Without Badge";
      return item;
    });
    return modifiedData;
  };

  const modifySummaryReportData = (data) => {
    let storeid = 0;
    let modifiedData = data.map((item) => {
      item["FormatedDateTime"] =
        item.delivery_Date &&
        item.delivery_Date.trim() &&
        moment(item.delivery_Date).format("MM-DD-YYYY");
      item["CleanWithFlag"] = item.clean_Times
        ? item.clean_Cheating
          ? item.clean_Times.toString()
          : item.clean_Times.toString()
        : "0";
      item["Badge_Type_Clean"] =
        item.clean_Cheating === true ? "Quick cleaned" : "Regular cleaned";
      item["total_LBS"] =
        (item.first_Grind_LBS ? Number(item.first_Grind_LBS) : 0) +
        (item.last_Grind_LBS ? Number(item.last_Grind_LBS) : 0);
      // item["last_Clean_After_Grind"] =
      //   item.last_Clean_After_Grind && item.last_Clean_After_Grind > 0 ? (
      //     item.last_Clean_After_Grind < 60 ? (
      //       <span>{item.last_Clean_After_Grind % 60} min</span>
      //     ) : (
      //       <span>
      //         {Math.floor(item.last_Clean_After_Grind / 60)} h{" "}
      //         {item.last_Clean_After_Grind % 60} min
      //       </span>
      //     )
      //   ) : (
      //     ""
      //   );
      // item["grind_Last"] =
      //   item.grind_Last &&
      //   item.grind_Last.trim() &&
      //   moment(item.grind_Last, ["HH:mm"]).format("hh:mm A");
      // item["last_Clean_ThatDay"] =
      //   item.last_Clean_ThatDay &&
      //   item.last_Clean_ThatDay.trim() &&
      //   moment(item.last_Clean_ThatDay, ["HH:mm"]).format("hh:mm A");

      // if (storeid === 0 || (storeid !== 0 && storeid !== item["store_ID"])) {
      //   storeid = item["store_ID"];
      //   let storedata = data.filter((x) => x["store_ID"] === storeid);
      //   if (storedata && storedata.length > 1 /* && groupType === 2 */)
      //     item["isbold"] = false;
      //   else item["isbold"] = false;
      // }

      return item;
    });
    return modifiedData;
  };

  const calTotalWeekRowData = (modifiedData, headers) => {
    let totalObj = {};
    headers.map((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                modifiedData.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                modifiedData.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              )
          : "";
    });
    // setTotalWeekTableRow(totalObj);
    return totalObj;
  };
  //#endregion

  //#region Dynamic Column Filter and Data
  const columnFiltersData = () => {
    let filtersArr = [];
    let headerData =
      selectedTab.label === "Detailed"
        ? ReportConstants[selectedReportType]
        : ReportConstants[selectedTab.label];
    let masterData =
      selectedTab.label === "Detailed"
        ? selectedReportType === "Grind Log Details"
          ? reportMasterList
          : reportMasterCaseList
        : reportMasterSummaryList;
    headerData.forEach((value) => {
      value.isSearch &&
        value.searchGroup.some((x) => x === groupType) &&
        filtersArr.push({
          key: value.DataKey,
          label: value.Header,
          data: [...new Set(masterData.map((_) => _[value.DataKey]))].sort(
            (a, b) => {
              let x = a ? a : "";
              let y = b ? b : "";
              return x
                .toString()
                .localeCompare(y.toString(), undefined, { numeric: true });
            }
          ),
        });
    });
    columnFiltersOnSelect();
    setColumnFilters(filtersArr);
  };

  const columnFiltersOnSelect = () => {
    let selectedFiltersArr = {};
    let headerData =
      selectedTab.label === "Detailed"
        ? ReportConstants[selectedReportType]
        : ReportConstants[selectedTab.label];
    headerData.forEach((value) => {
      if (value.isSearch && value.searchGroup.some((x) => x === groupType)) {
        selectedFiltersArr[value.DataKey] = [];
      }
    });
    setFilterCount(selectedFiltersArr);
    setSelectedColumnFilters(selectedFiltersArr);
    filterSelected(selectedFiltersArr, selectedFiltersArr);
  };

  const getCheckPickerData = (result) => {
    let valueArr = [];
    if (result.data) {
      valueArr = result.data.map((res) => {
        return { label: res !== "" ? res : "(Empty)", value: res };
      });
    }
    return valueArr;
  };
  //#endregion

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Apply and Reset Filter Button Validation
  const filterSelected = (filterCount, selectedData) => {
    let isSelected = false;
    for (const data in filterCount) {
      if (
        filterCount[data] &&
        selectedData[data] &&
        filterCount[data].length !== selectedData[data].length
      )
        isSelected = true;
    }
    setFilterSelected(isSelected);
  };
  //#endregion

  //#region ClearFilter
  const clearFilter = () => {
    //setSelectedColumnFilters({});
    columnFiltersOnSelect();
    filterSelected(filterCount, filterCount);
    setListTableReports(
      selectedTab.label === "Detailed"
        ? selectedReportType === "Grind Log Details"
          ? reportMasterList
          : reportMasterCaseList
        : reportMasterSummaryList
    );
  };
  //#endregion

  //#region Panel Filter Onclick
  const onFilterClick = (selectedColumnFilters) => {
    let data =
      selectedTab.label === "Detailed"
        ? selectedReportType === "Grind Log Details"
          ? reportMasterList
          : reportMasterCaseList
        : reportMasterSummaryList;
    data = data.filter((result) => {
      let selected = true;
      for (let prop in selectedColumnFilters) {
        if (selected) {
          selected =
            selectedColumnFilters[prop] &&
            selectedColumnFilters[prop].length > 0
              ? selectedColumnFilters[prop].some(
                  (item) => item.toString() === result[prop].toString()
                )
              : true;
        }
      }
      return selected;
    });
    setListTableReports(data);
    // selectedTab.label === "Detailed" ? selectedReportType === "Grind Log Details" ? setReportList(data) : setReportCaseList(data) : setReportSummaryList(data);
    setDisplayNoDatatlabel(data.length === 0);
  };
  //#endregion

  //#region Detail Report Type Selection
  const onRadioClick = (type) => {
    setSelectedReportType(type);
  };
  //#endregion
  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });

  return (
    <>
      <div className="TrackITReportHeader">
        <>
          <div className="TrackITReportHeaderItems">
            <InputPicker
              className="InputPicker"
              onChange={(e) => {
                if (e.toString() === "1") {
                  setSelectedTab({ label: "Detailed", value: "1" });
                } else {
                  setSelectedTab({ label: "Summary", value: "2" });
                }
              }}
              size="md"
              placeholder="Detailed"
              data={tabsList.map((item) => {
                return { label: item.label, value: item.value };
              })}
              cleanable={false}
            />
          </div>

          <div className="TrackITReportHeaderItems">
            {selectedTab.label === "Detailed" && (
              <div>
                <ButtonToolbar>
                  <ButtonGroup style={{ display: "flex" }}>
                    <Button
                      appearance={
                        selectedReportType === "Grind Log Details"
                          ? "primary"
                          : "ghost"
                      }
                      onChange={(reportTypeSelected) => {
                        onRadioClick(reportTypeSelected);
                      }}
                      onClick={() => {
                        setSelectedReportType("Grind Log Details");
                      }}
                    >
                      Grind Log Details
                    </Button>
                    <Button
                      appearance={
                        selectedReportType === "Grind Log Case"
                          ? "primary"
                          : "ghost"
                      }
                      onChange={(reportTypeSelected) => {
                        onRadioClick(reportTypeSelected);
                      }}
                      onClick={() => {
                        setSelectedReportType("Grind Log Case");
                      }}
                    >
                      Grind Log Case
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            )}
          </div>
          <div className="TrackITReportHeaderItems">
            {userContext &&
              userContext.roleName &&
              (userContext.roleName === "Super Admin" ||
                userContext.groupTypeId !== "0" ||
                userContext.groupTypeName === "" ||
                userContext.store === 0) && (
                <>
                  <>
                    <div className="d-flex nowrap">
                      {userContext && userContext.roleName === "Super Admin" ? (
                        <div className="GroupStoreSide">
                          <SelectPicker
                            className="header-bar-item"
                            placeholder="GOT"
                            onChange={SelectAwgOrGot}
                            label="Group Type"
                            data={awgOrGot.map((item) => ({
                              label: item.groupStore_desc,
                              value: item.groupStore_uid,
                            }))}
                          />
                          <SelectPicker
                            style={{ maxWidth: "300px" }}
                            onChange={SelectGroupType}
                            label="Group"
                            className="header-bar-item"
                            virtualized
                            data={groupAndGroupID.map((item) => ({
                              label: item.groupStore_desc,
                              value: item.groupStore_uid,
                            }))}
                          />
                          <SelectPicker
                            style={{ maxWidth: "300px" }}
                            onChange={onOptionChangeHandler}
                            label="Store"
                            className="header-bar-item"
                            virtualized
                            placeholder={
                              storeName && storeName[0] ? storeName[0] : "All"
                            }
                            data={storeName.map((item, index) => ({
                              label: item,
                              value: index,
                            }))}
                          />
                        </div>
                      ) : userContext && userContext.store !== 0 ? (
                        <>
                          <label
                            style={{ height: "30px", fontWeight: 700 }}
                            className=" alert"
                          >
                            Store: {storeNameHeader}
                          </label>{" "}
                        </>
                      ) : userContext &&
                        userContext.groupTypeId &&
                        userContext.groupTypeId !== "" ? (
                        <>
                          {" "}
                          <SelectPicker
                            className="header-bar-item"
                            onChange={onOptionChangeHandler}
                            label="Store"
                            placeholder={
                              storeName && storeName[0] ? storeName[0] : "All"
                            }
                            data={storeName.map((item, index) => ({
                              label: item,
                              value: index,
                            }))}
                          />
                        </>
                      ) : (
                        <>
                          <SelectPicker
                            onChange={SelectGroupType}
                            label="Group"
                            style={{ maxWidth: "250px" }}
                            className="header-bar-item"
                            virtualized
                            data={groupAndGroupID.map((item) => ({
                              label: item.groupStore_desc,
                              value: item.groupStore_uid,
                            }))}
                          />
                          {
                            <>
                              {" "}
                              <SelectPicker
                                onChange={onOptionChangeHandler}
                                label="Store"
                                placeholder={
                                  storeName && storeName[0]
                                    ? storeName[0]
                                    : "All"
                                }
                                virtualized
                                data={storeName.map((item, index) => ({
                                  label: item,
                                  value: index,
                                }))}
                              />
                            </>
                          }
                        </>
                      )}
                    </div>
                  </>
                </>
              )}
          </div>
          <div className="TrackITReportHeaderItems">
            <div className="d-flex justify-content-between align-items-center">
              <Calendar
                value={[fromDate, toDate]}
                calenderOnClick={calenderOnClick}
              />
            </div>
          </div>
        </>
      </div>

      <div className="mt-2 d-flex justify-content-end align-items-end">
        {selectedTab.label === "Detailed" &&
        selectedReportType === "Grind Log Details" &&
        reportList.length > 0 ? (
          <div className="">
            <span className="badge-legend">
              {" "}
              Note: <Icon className="flag-icon2" icon="ep:flag" /> Previously
              Used{" "}
            </span>{" "}
          </div>
        ) : selectedTab.label === "Summary" && reportSummaryList.length > 0 ? (
          <div className="">
            <span className="badge-legend">
              {" "}
              Note: <Icon className="flag-icon2" icon="ep:flag" /> Quick Cleaned{" "}
            </span>{" "}
          </div>
        ) : (
          ""
        )}
        {columnFilters && columnFilters.length > 0 && (
          <div
            id="filterdiv"
            className="mx-3 filter"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            onClick={toggleCollapse}
          >
            <Icon icon="material-symbols:filter-alt-outline-sharp" /> Filters
          </div>
        )}
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(listTableReports, "Grind Log Report");
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>
      {columnFilters && columnFilters.length > 0 && (
        <div
          className={`collapse p-2 mt-3 boxallign ${isCollapsed ? "" : "show"}`}
          id="collapseExample"
        >
          <div className="card card-body totalCard">
            {columnFilters &&
              columnFilters.length > 0 &&
              columnFilters.map((result) => {
                return (
                  <div className="collapsedData pe-2  ">
                    <CheckPicker
                      sticky
                      preventOverflow
                      className="w-100 filtersPicker"
                      menuMaxHeight={190}
                      appearance="default"
                      ref={picker}
                      size="xs"
                      renderValue={(value, items) => {
                        const _value = [...value].sort((a, b) => {
                          let x = a ? a : "";
                          let y = b ? b : "";
                          return x
                            .toString()
                            .localeCompare(y.toString(), undefined, {
                              numeric: true,
                            });
                        });
                        _value[0] === "" && _value.splice(0, 1, "(Empty)");
                        return (
                          <>
                            {_value.length < 3
                              ? _value.splice(0, 2).join(", ")
                              : _value[0] !== "(Empty)"
                              ? _value.splice(0, 2).join(", ")
                              : _value.splice(1, 2).join(", ")}{" "}
                            {_value.length > 0 ? (
                              <span className="roundOff">
                                {" "}
                                {`+${_value.length}`}
                              </span>
                            ) : (
                              ``
                            )}
                          </>
                        );
                      }}
                      data={getCheckPickerData(result)}
                      style={{ width: 224 }}
                      value={selectedColumnFilters[result.key]}
                      placeholder={result.label}
                      renderMenuItem={(label, item) => {
                        return (
                          <div className="d-flex flex-column">
                            <i className="rs-icon rs-icon-user" /> {label}
                          </div>
                        );
                      }}
                      onChange={(value, event) => {
                        let selectedData = { ...selectedColumnFilters };
                        selectedData[result.key] = value;
                        setSelectedColumnFilters(selectedData);
                        filterSelected(filterCount, selectedData);
                        onFilterClick(selectedData);
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div className="footer text-end boxdetails">
            <Button
              appearance="ghost"
              className="mx-2"
              size="xs"
              disabled={!isfilterSelected}
              onClick={() => {
                clearFilter();
              }}
            >
              Clear All
            </Button>
            <IconButton
              appearance="ghost"
              style={{ padding: "0.35rem" }}
              icon={<Icon icon="ic:twotone-close" />}
              size="xs"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
            />
          </div>
        </div>
      )}

      <TableComponent
        isFromModal={false}
        tableDetails={listTableHeaders}
        reportList={listTableReports}
        iconColumn={"NA"}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={listTableTotals}
        isTotalRowRequired={
          selectedTab.label === "Detailed" &&
          selectedReportType === "Grind Log Case"
            ? true
            : false
        }
        DisplayNoDatatlabel={DisplayNoDatatlabel}
        isTableCustomStriped={
          groupType /* === 1 */ &&
          moment(fromDate).format("MM-DD-YYYY") !==
            moment(toDate).format("MM-DD-YYYY")
            ? selectedTab.label === "Summary"
              ? true
              : false
            : false
        }
      ></TableComponent>
    </>
  );
};

export default ReportGrindLogComponent;
