import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";
import { Button } from "rsuite";
const DepToStoreDepartment = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const {
    value,
    toDate,
    fromDate,
    MainLabel,
    setShowDepartment,
    setDepToStoreStore,
    scrollTo,
    ref1,
    ref2,
    setFirstLayer,
    setSecondLayer,
    setThirdLayer,
  } = props;

  const [specificLabel, setSpecificLabel] = useState("Seasonal");
  if (specificLabel.includes(" ")) {
    specificLabel.replaceAll(" ", "_");
  }
  useEffect(() => {
    setSpecificLabel(MainLabel);
  }, [MainLabel]);

  // Data manipulation
  const labels = Object.keys(value);

  const showRightData = labels.map((label) =>
    parseFloat(value[label][specificLabel]).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  );

  const showRightDataNoPerSign = labels.map((label) =>
    parseFloat(value[label][specificLabel])
  );
  const combineObject = labels.map((label, index) => ({
    label,
    value: showRightDataNoPerSign[index],
  }));

  combineObject.sort((a, b) => b.value - a.value);

  const newSortLabels = [];
  const newSortValues = [];
  combineObject.map((object) => {
    return newSortLabels.push(object.label);
  });
  combineObject.map((object) => {
    return newSortValues.push(
      object.value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    );
  });

  const allOfData = (state) => {
    switch (state) {
      case "":
        break;
      case "Short_Date":
        return labels.map((label) => value[label]["Short_Date"].toFixed(2));
      case "Manager_Special":
        return labels.map((label) =>
          value[label]["Manager_Special"].toFixed(2)
        );
      case "Seasonal":
        return labels.map((label) => value[label]["Seasonal"].toFixed(2));
      case "Disc_By_Vendor":
        return labels.map((label) => value[label]["Disc_By_Vendor"].toFixed(2));

      case "Disc_By_Office":
        return labels.map((label) => value[label]["Disc_By_Office"].toFixed(2));

      case "Mispick":
        return labels.map((label) => value[label]["Mispick"].toFixed(2));
      case "Damaged":
        return labels.map((label) => value[label]["Damaged"].toFixed(2));
      case "Short_Date_From_W/H":
        return labels.map((label) =>
          value[label]["Short_Date_From_W/H"].toFixed(2)
        );
      default:
        setSpecificLabel(state);
    }
  };
  if (
    allOfData(specificLabel) !== undefined &&
    allOfData(specificLabel) !== null
  ) {
    const numberValue = allOfData(specificLabel).map((value) => Number(value));
    var newPercentages = calculatePercentages(numberValue);
  }
  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }
  //Change labels "_" to " "
  const SortLabels = specificLabel.includes("_")
    ? specificLabel.replaceAll("_", " ")
    : specificLabel;

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,
    plugins: {
      legend: {
        position: "right" as const,
        display: false,
      },
      title: {
        display: true,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },
      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  const data = {
    labels: labels.map((label) => label.trim()),
    datasets: [
      {
        data: newPercentages,
        backgroundColor: [
          "rgba(20, 36, 89, 0.5)",
          "rgba(23, 107, 160, 0.5)",
          "rgba(25, 170, 222, 0.5)",
          "rgba(26, 201, 230, 0.5)",
          "rgba(27, 212, 212, 0.5)",
          "rgba(29, 228, 189, 0.5)",
          "rgba(199, 249, 238, 0.5)",
        ],
        borderColor: [
          "#186BA1",
          "#19ABDE",
          "#1AC9E6",
          "#1CD4D4",
          "#1DE4BD",
          "#6DF0D1",
          "#C7F9EE",
        ],
        spacing: 15,
        hoverOffset: 5,
      },
    ],
  };
  const charTextColor = [
    "rgba(20, 36, 89, 0.6)",
    "rgba(23, 107, 160, 0.6)",
    "rgba(25, 170, 222, 0.6)",
    "rgba(26, 201, 230, 0.6)",
    "rgba(27, 212, 212, 0.6)",
    "rgba(29, 228, 189, 0.6)",
    "rgba(199, 249, 238, 0.6)",
  ];
  return (
    <div ref={ref1}>
      <div className="chart-heading">{`${SortLabels} Department Breakdown`}</div>
      <div className="programming-stats-dashboard">
        <div className="center-chart">
          <Doughnut options={options} data={data} />
        </div>
        <div className="details-chart">
          <ul>
            {newSortLabels.map((label, index) => {
              return (
                <li className="chart-li" key={index}>
                  <div
                    style={{
                      color: charTextColor[index],
                      fontWeight: "700",
                      filter: "contrast(300)",
                    }}
                    className="chart-label"
                    onClick={(e) => {
                      setShowDepartment(e.currentTarget.innerHTML);
                      setDepToStoreStore(true);
                      scrollTo(ref2);
                      setSecondLayer(false);
                      setThirdLayer(true);
                    }}
                  >
                    {label}
                  </div>
                  <span className="percantage">
                    {" " + newSortValues[index]}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2">
        {" "}
        <Button
          appearance="primary"
          onClick={(e) => {
            setFirstLayer(true);
            setSecondLayer(false);
          }}
        >
          Previous
        </Button>
      </div>
    </div>
  );
};

export default DepToStoreDepartment;
