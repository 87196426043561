import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";
import { Button } from "rsuite";
const DepToStoreDepartment = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const {
    value,
    toDate,
    fromDate,
    MainLabel,
    setShowDepartment,
    setDepToStoreStore,
    scrollTo,
    ref4,
    ref3,
    setFirstLayer,
    setSecondLayer,
    setThirdLayer,
  } = props;

  const [specificLabel, setSpecificLabel] = useState("");

  useEffect(() => {
    setSpecificLabel(MainLabel);
  }, [MainLabel]);

  // Data manipulation
  const labels = Object.keys(value);

  const showRightData = labels.map((label) =>
    parseFloat(value[label][specificLabel]).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  );

  const showRightDataNoPerSign = labels.map((label) =>
    parseFloat(value[label][specificLabel])
  );
  const combineObject = labels.map((label, index) => ({
    label,
    value: showRightDataNoPerSign[index],
  }));

  combineObject.sort((a, b) => b.value - a.value);

  const newSortLabels = [];
  const newSortValues = [];
  combineObject.map((object) => {
    return newSortLabels.push(object.label);
  });
  combineObject.map((object) => {
    return newSortValues.push(
      object.value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    );
  });

  const allOfData = (state) => {
    switch (state) {
      case "":
        break;
      case "Spoilage":
        return labels.map((label) => value[label]["Spoilage"].toFixed(2));
      case "Damage":
        return labels.map((label) => value[label]["Damage"].toFixed(2));
      case "AWG":
        return labels.map((label) => value[label]["AWG"].toFixed(2));
      case "Out_of_Date":
        return labels.map((label) => value[label]["Out_of_Date"].toFixed(2));

      case "Recovery":
        return labels.map((label) => value[label]["Recovery"].toFixed(2));

      case "Theft":
        return labels.map((label) => value[label]["Theft"].toFixed(2));
      default:
        setSpecificLabel(state);
    }
  };
  if (
    allOfData(specificLabel) !== undefined &&
    allOfData(specificLabel) !== null
  ) {
    const numberValue = allOfData(specificLabel).map((value) => Number(value));
    var newPercentages = calculatePercentages(numberValue);
  }
  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }
  //Change labels "_" to " "
  const SortLabels = specificLabel.includes("_")
    ? specificLabel.replaceAll("_", " ")
    : specificLabel;

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,
    plugins: {
      legend: {
        position: "right" as const,
        display: false,
      },
      title: {
        display: true,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },
      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: newPercentages,
        backgroundColor: [
          "rgba(20, 36, 89, 0.5)",
          "rgba(23, 107, 160, 0.5)",
          "rgba(25, 170, 222, 0.5)",
          "rgba(26, 201, 230, 0.5)",
          "rgba(27, 212, 212, 0.5)",
          "rgba(29, 228, 189, 0.5)",
          "rgba(199, 249, 238, 0.5)",
        ],
        borderColor: [
          "#186BA1",
          "#19ABDE",
          "#1AC9E6",
          "#1CD4D4",
          "#1DE4BD",
          "#6DF0D1",
          "#C7F9EE",
        ],
        spacing: 15,
        hoverOffset: 5,
      },
    ],
  };
  const charTextColor = [
    "rgba(20, 36, 89, 0.6)",
    "rgba(23, 107, 160, 0.6)",
    "rgba(25, 170, 222, 0.6)",
    "rgba(26, 201, 230, 0.6)",
    "rgba(27, 212, 212, 0.6)",
    "rgba(29, 228, 189, 0.6)",
    "rgba(199, 249, 238, 0.6)",
  ];
  return (
    <div ref={ref3}>
      <div className="chart-heading">{`${SortLabels} Department Breakdown`}</div>
      <div className="programming-stats-dashboard">
        <div className="center-chart">
          <Doughnut options={options} data={data} />
        </div>
        <div className="details-chart">
          <ul>
            {newSortLabels.map((label, index) => {
              return (
                <li className="chart-li">
                  <div
                    style={{
                      color: charTextColor[index],
                      fontWeight: "700",
                      filter: "contrast(300)",
                    }}
                    className="chart-label"
                    onClick={(e) => {
                      setSecondLayer(false);
                      setThirdLayer(true);
                      setShowDepartment(e.currentTarget.innerHTML);
                      setDepToStoreStore(true);
                      scrollTo(ref4);
                    }}
                  >
                    {label}
                  </div>
                  <span className="percantage">
                    {" " + newSortValues[index]}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2">
        {" "}
        <Button
          appearance="primary"
          onClick={(e) => {
            setFirstLayer(true);
            setSecondLayer(false);
          }}
        >
          Previous
        </Button>
      </div>
    </div>
  );
};

export default DepToStoreDepartment;
