/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { Dropdown, Modal } from "rsuite";
import { Icon } from "@iconify/react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as AuthService from "../../../services/authService";
import * as ReportService from "../../../services/reportServices";
import * as SettingsServices from "../../../services/settingsServices";
import * as UMSService from "../../../services/umsService";
import MainTableComponent from "./MainTableComponent";
import DetailTableComponent from "./DetailTableComponent";
import { ReportProductMixOptConstants } from "./reportProductMixOptConstants";
import DetailLastTableComponent from "./DetailLastTableComponent";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";

const ReportProductMixOptComponent = (props: any) => {
  const { handleSpinner, userContext = {} } = props;

  const date = new Date();

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow1, setSelectedRow1] = useState(null);
  const [productData, setProductData] = useState([]);
  const [productDataDescription1, setProductDataDescription1] = useState([]);
  const [productDataDescription2, setProductDataDescription2] = useState([]);

  const [allStoreName, setAllStoreName] = useState([]);
  const [allObj, setAllObj] = useState({ items: 0, itemsCount: 0 });

  const [storeName, setStoreName] = useState([]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [storeID, setStoreID] = useState(
    userContext && userContext.store !== 0 ? userContext.store : " "
  );

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");

  const refAll = useRef();
  function scrollTo(ref) {
    if (ref.current) {
    }
  }
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const handleClose = () => {
    setOpen(false);
    setSelectedRow("");
    //setOpenDetail(false);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setSelectedRow1("");
    //setOpenDetail(false);
  };
  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getData(userContext.store);
    } else {
      getData(userStore);
    }
  }, [userStore, userContext]);

  useEffect(() => {
    selectedRow &&
      selectedRow.commodity_IDGeneral &&
      Description1(selectedRow.commodity_IDGeneral);
  }, [selectedRow]);

  useEffect(() => {
    selectedRow1 &&
      selectedRow1.commodity_IDSpecific &&
      Description2(selectedRow1.commodity_IDSpecific);
  }, [selectedRow1]);
  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, store, user_ID } = userContext;
    handleSpinner && handleSpinner(true);

    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    if (storeID) {
      setUserStore(storeID[0]);
    }
  };
  const SelectAwgOrGot = (event) => {
    //console.log(event.target.value);
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
  };

  const getData = async (Store_ID) => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId } = userContext;
    handleSpinner && handleSpinner(true);
    await ReportService.GetProductMixOptReport(Store_ID)
      .then((result) => {
        result.recordsGroupCommodity = result.recordsGroupCommodity.filter(
          function (item) {
            return item.commodity_IDGeneral !== "";
          }
        );

        result.recordsGroupCommodity.map((item) => {
          item.itemsCount = item.records.length;
        });
        setAllObj({
          items: result.totalRecord,
          itemsCount: result.totalGroupRecord,
        });
        setProductData(result.recordsGroupCommodity);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const Description1 = (description1) => {
    productData.map((item) => {
      if (item.commodity_IDGeneral === description1) {
        setProductDataDescription1(item.records);
      }
    });
    if (productDataDescription1) {
      setOpen(true);
    }

    //setProductDataDescription1(descriptiondata);
  };

  const Description2 = (description2) => {
    productDataDescription1.map((item) => {
      if (item.commodity_IDSpecific === description2) {
        setProductDataDescription2(item.records1);
      }
    });
    if (productDataDescription2) {
      setOpen1(true);
    }

    //setProductDataDescription1(descriptiondata);
  };
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  return (
    <>
      <div className="d-flex flex-row justify-content-start align-items-center">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          isVendor={false}
          isCalendar={false}
          isDepartment={false}
        />
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(
                productData,
                `Product Report ${storeName + userStore}`
              );
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>

      {productData.length !== 0 || true ? (
        <MainTableComponent
          userContext={userContext}
          isFromModal={false}
          tableDetails={ReportProductMixOptConstants["MainPage"]}
          reportList={productData}
          iconColumn={"commodity_IDGeneral"}
          onTableRowClick={() => {}}
          refAll={refAll}
          setSelectedRow={setSelectedRow}
          scrollTo={scrollTo}
          showAll={false}
          reportType={""}
          totalTableRow={allObj}
          isTotalRowRequired={true}
        />
      ) : null}
      {productDataDescription1 && (
        <Modal size={"full"} open={open} onClose={handleClose}>
          <Modal.Header>
            <div>
              <Modal.Title>
                <span
                  className="mx-2 filter"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon icon="ep:back" />
                </span>
                <div style={{ textAlign: "center" }}>
                  Product Mix Optimiziation Detail
                </div>
              </Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3">
              {selectedRow && selectedRow.commodity_IDGeneral && (
                <div className="col-3 p-2 reportDescItem">
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Product General Name</p>
                    <p className="m-0">
                      {selectedRow && selectedRow.commodity_IDGeneral}
                    </p>
                  </div>
                </div>
              )}
              <div className="col-3 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">
                    {userContext && userContext.store && userContext.store !== 0
                      ? storeName[0]
                      : storeID[0]}
                  </p>
                </div>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Date</p>
                  <p className="m-0">
                    {("00" + (date.getMonth() + 1)).slice(-2) +
                      "/" +
                      ("00" + date.getDate()).slice(-2) +
                      "/" +
                      date.getFullYear() +
                      " " +
                      ("00" + date.getHours()).slice(-2) +
                      ":" +
                      ("00" + date.getMinutes()).slice(-2) +
                      ":" +
                      ("00" + date.getSeconds()).slice(-2)}
                  </p>
                </div>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <Dropdown
                  renderToggle={(_props, _ref) => (
                    <div className="filter" ref={_ref} {..._props}>
                      <Icon icon="pajamas:export" /> Export
                    </div>
                  )}
                  className="ms-2 me-0 button-fixed"
                  appearance="link"
                  placement="bottomEnd"
                >
                  <Dropdown.Item
                    icon={<FileDownloadIcon />}
                    onClick={() => {
                      exportToExcel(
                        productDataDescription1,
                        `Product Mix Optimization Detail Report ${
                          (userContext &&
                          userContext.store &&
                          userContext.store !== 0
                            ? storeName
                            : userStore) +
                          " " +
                          ("00" + (date.getMonth() + 1)).slice(-2) +
                          "/" +
                          ("00" + date.getDate()).slice(-2) +
                          "/" +
                          date.getFullYear() +
                          " " +
                          ("00" + date.getHours()).slice(-2) +
                          ":" +
                          ("00" + date.getMinutes()).slice(-2) +
                          ":" +
                          ("00" + date.getSeconds()).slice(-2)
                        }`
                      );
                    }}
                  >
                    Export to Excel
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            {productDataDescription1 && (
              <DetailTableComponent
                isFromModal={false}
                tableDetails={ReportProductMixOptConstants["Detail1Page"]}
                reportList={productDataDescription1}
                setSelectedRow={setSelectedRow1}
                iconColumn={"commodity_IDSpecific"}
                onTableRowClick={() => {}}
                reportType={""}
                totalTableRow={{}}
                isTotalRowRequired={false}
              />
            )}
          </Modal.Body>
        </Modal>
      )}

      {productDataDescription2 && (
        <Modal size={"full"} open={open1} onClose={handleClose1}>
          <span
            className="mx-2 filter"
            onClick={() => {
              setOpen1(false);
              setSelectedRow1("");
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <Modal.Header>
            <div style={{ textAlign: "center" }}>
              <Modal.Title>Product Mix Optimization Detail 2</Modal.Title>
            </div>

            <div className="bg-container row m-0 mt-3 p-3">
              {selectedRow && selectedRow.commodity_IDGeneral && (
                <div className="col-3 p-2 reportDescItem">
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Product General Name</p>
                    <p className="m-0">
                      {selectedRow && selectedRow.commodity_IDGeneral}
                    </p>
                  </div>
                </div>
              )}
              {selectedRow1 && selectedRow1.commodity_IDSpecific && (
                <div className="col-3 p-2 reportDescItem">
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Product Specific Name</p>
                    <p className="m-0">
                      {selectedRow1 && selectedRow1.commodity_IDSpecific}
                    </p>
                  </div>
                </div>
              )}
              <div className="col-3 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Vendor Name</p>
                  <p className="m-0">
                    {productDataDescription2 &&
                      productDataDescription2[0] &&
                      productDataDescription2[0].vendor}
                  </p>
                </div>
              </div>

              <div className="col-3 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Commodity ID</p>
                  <p className="m-0">
                    {" "}
                    {productDataDescription2 &&
                      productDataDescription2[0] &&
                      productDataDescription2[0].commodity_ID}
                  </p>
                </div>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">
                    {userContext && userContext.store && userContext.store !== 0
                      ? storeName[0]
                      : storeID[0]}
                  </p>
                </div>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Date</p>
                  <p className="m-0">
                    {("00" + (date.getMonth() + 1)).slice(-2) +
                      "/" +
                      ("00" + date.getDate()).slice(-2) +
                      "/" +
                      date.getFullYear() +
                      " " +
                      ("00" + date.getHours()).slice(-2) +
                      ":" +
                      ("00" + date.getMinutes()).slice(-2) +
                      ":" +
                      ("00" + date.getSeconds()).slice(-2)}
                  </p>
                </div>
              </div>

              <div className="col-3 p-2 reportDescItem">
                <Dropdown
                  renderToggle={(_props, _ref) => (
                    <div className="filter" ref={_ref} {..._props}>
                      <Icon icon="pajamas:export" /> Export
                    </div>
                  )}
                  className="ms-2 me-0 button-fixed"
                  appearance="link"
                  placement="bottomEnd"
                >
                  <Dropdown.Item
                    icon={<FileDownloadIcon />}
                    onClick={() => {
                      exportToExcel(
                        productDataDescription2,
                        `Product Mix Optimization Detail 2 Report ${
                          (userContext &&
                          userContext.store &&
                          userContext.store !== 0
                            ? storeName
                            : userStore) +
                          " " +
                          ("00" + (date.getMonth() + 1)).slice(-2) +
                          "/" +
                          ("00" + date.getDate()).slice(-2) +
                          "/" +
                          date.getFullYear() +
                          " " +
                          ("00" + date.getHours()).slice(-2) +
                          ":" +
                          ("00" + date.getMinutes()).slice(-2) +
                          ":" +
                          ("00" + date.getSeconds()).slice(-2)
                        }`
                      );
                    }}
                  >
                    Export to Excel
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DetailLastTableComponent
              isFromModal={false}
              tableDetails={ReportProductMixOptConstants["DetailLastPage"]}
              reportList={productDataDescription2}
              iconColumn={""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{}}
              isTotalRowRequired={false}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ReportProductMixOptComponent;
