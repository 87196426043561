const RoutingConstants = {
  // All the routes will be declared here and will use them in Link and Route tags for routing.
  default: "/",
  home: "/home",
  beta: "https://testportal.gotsystems.net/",

  // Auth
  signinCallback: "/login-callback",
  userDoesNotExist: "/user-does-not-exist",
  userGroups: "/users-groups",

  // Main
  main: "/MainScreen/Index?redirectionTomain=true",
  mainAllFiles: "/MainScreen/Index?redirectionTomain=true",
  mainCredits: "/MainScreen/Index?redirectionTomain=true",
  mainInventory: "/MainScreen/Index?redirectionTomain=true",
  mainOrders: "/MainScreen/Index?redirectionTomain=true",
  mainReceipts: "/MainScreen/Index?redirectionTomain=true",

  // File Import
  fileImport: "/file-import",
  fileImportLoadFiles: "/file-import/load_files",
  fileImportInvoices: "/file-import/invoices",
  fileImportVendorStatement: "/file-import/vendor_statement",
  fileImportImportsOrdersOrRec: "/file-import/imports_orders_or_rec",
  fileImportLoadMarkdownExcelRameysNewFormat:
    "/file-import/load_markdown_excel_rameys_new_format",
  fileImportCostPrebook: "/file-import/cost_prebook",
  fileImportAddAndTprLoads: "/file-import/add_and_tpr_loads",
  fileImportInvoicesNew: "/file-import/invoices_new",
  fileImportBallsOrderBook: "/file-import/balls_order_book",
  fileImportManualLblLoads: "/file-import/manual_lbl_loads",
  fileImportBrDataRaw: "/file-import/br_data_raw",
  fileImportMarkdownDiscount: "/file-import/markdown_discount",
  fileImportLoadBnzl: "/file-import/load_bnzl",
  fileImportBallsPriceFileConvert: "/file-import/balls_price_file_convert",
  fileImportExportInvoices: "/file-import/export_invoices",

  // View
  view: "/view",
  viewCredits: "/view/credits",
  viewNoOrders: "/view/no_orders",
  viewReceipts: "/view/receipts",
  viewWarehouseRouting: "/view/warehouse_routing",
  viewPrebook: "/view/prebook",
  viewOrderSavingsScreens: "/view/order_savings_screens",
  viewLastDayToSaveScreen: "/view/last_day_to_save_screen",
  viewOrderSavingScreenTest: "/view/order_saving_screen_test",
  viewOutOfStock: "/view/out_of_stock",
  viewOutOfStockHistory: "/view/out_of_stock_history",
  viewLog: "/view/log",
  viewTransactions: "/transactions",
  viewInstoreSpecial: "/view/instore_special",
  viewManageInStoreSpecialItems: "/view/manage_in_store_special_items",
  viewCreditActivity: "/view/credit_activity",
  viewCreditActivityNotMatchGroup: "/view/credit_activity_not_match_group",
  viewCreditActivityNotMatchStore: "/view/credit_activity_not_match_store",
  viewCreditLateSchedule: "/view/credit_late_schedule",
  viewInventory: "/view/inventory",
  viewAdvancedOrders: "/view/advanced_orders",
  viewOrderWh: "/view/order_wh",
  viewOrder1: "/view/order_1",

  // Orders
  orders: "/Orders/Index?redirectionToorders=true",
  ordersView: "/orders/view",
  ordersCreate: "/orders/create",
  ordersAdusted: "/orders/adusted-orders",
  ordersConfirmationList:
    "/orders/ConfirmationList?redirectionToConfirmationLst=true",
  ordersConfirmationListPortal: "/orders/confirmation-list",
  ordersConfirmationListDepartmentPortal: "/orders/ConfirmationListDepartment",
  ordersBadItems: "/orders/item-inquiry",
  ordersOutOfStock: "/orders/out-of-stock",
  order_savings: "/orders/order-savings",
  reportProductMixOptimization: "/orders/product-mix-optimization",
  reportLastDayToSave: "/orders/last-day-to-save",

  // Orders Summary
  ordersSummary: "/orders-summary",
  utilities: "/utilities",

  // Pricing
  pricing: "/pricing",
  pricingZoneChange: "/Pricing/ZonePriceChangesOneStore",

  // User Management
  userManagement: "/UMS",
  userManagementRoles: "/UMS/RolesList",
  userManagementUsers: "/UMS/UsersList",
  userManagementLoginHistory: "/UMS/UserLoginHistory",
  userManagementDefaultPrivileges: "/DefaultPrivileges/SetDP",

  // Reports
  reports: "/reports",
  reportOrders: "/reports/order-book",
  reportCommodity: "/reports/commodity",
  reportTrackIt: "/reports/track-it",
  reportTrackItByGroup: "/reports/track-it-group",
  reportMarkdown: "/reports/markdown",
  reportGrindLog: "/reports/grind-log",
  reportDonation: "/reports/donation",
  reportReceiving: "/reports/receiving",
  reportCredits: "/reports/credits",
  reportOrdering: "/report/ordering-report",
  reportInventory: "/reports/inventory",
  reportOrdersByDepartment: "/orders-by-department",
  reportOrdersByDepartment2: "/orders-by-department-2",
  reportPlacement: "/reports/placement",

  // Schedules
  schedules: "/schedules",
  dashboard: "/dashboard",

  // Settings
  delivery_schedule: "settings/delivery-schedule",
  equipment_by_store: "settings/equipment-by-store",
  markdown_setup: "settings/markdown-setup",
  milk_order_book: "settings/milk-order-book",
  milk_delivery_schedule: "settings/milk-delivery-schedule",
  order_receipt: "settings/order-receipt",
  order_detail_amp: "settings/order-detail-amp",
  // Footer Nav
  settings: "/settings",
  helpdesk: "/helpdesk",
};

export default RoutingConstants;
