import { useState, useEffect } from "react";
import MarkDownContainer from "./dashboardCharts/MarkDown/MarkDownContainer";
import TrackITContainer from "./dashboardCharts/TrackIT/TrackITContainer";
import { addDays } from "date-fns";
import MainScreen from "./dashboardCharts/MainScreen/MainScreen";
import PageHeaderComponent from "./common/pageHeaderComponent";
import { Modal, Dropdown } from "rsuite";
import * as UMSService from "../services/umsService";
import * as SettingsServices from "../services/settingsServices";
import GrindMain from "./dashboardCharts/GrindCharts/GrindMain";
import CleanMain from "./dashboardCharts/GrindCharts/CleanMain";
import TrimMain from "./dashboardCharts/GrindCharts/TrimMain";
import { GrindConstants } from "./dashboardCharts/GrindCharts/GrindConstants";
import TableComponent from "../components/common/tableComponent";
import Chart from "react-apexcharts";
import moment from "moment";
import { Icon } from "@iconify/react";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from ".././utils/constants";
import * as AuthService from "../services/authService";
import PlacementContainer from "./dashboardCharts/Placement/PlacementContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  setGroupRecord,
  setStoreRecord,
} from "../redux/filterSelection/filterSelectionSlice";
import "./styles/_main-dashboard.scss";
import GrindLogTable from "./dashboardCharts/GrindCharts/GrindLogTable";

export const awgOrGot = [
  { groupStore_uid: "1", groupStore_desc: "GOT" },
  { groupStore_uid: "2", groupStore_desc: "AWG" },
  { groupStore_uid: "3", groupStore_desc: "MDI" },
];
const MainDashboard = (props: any) => {
  const { userContext = {} } = props;
  type ValidChartType = "bar" | "line" | "area";
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    chosenGroup,
    chosenGroupType,
    chosenGroupTypeName,
    chosenGroupName,
    chosenStore,
    chosenStoreName,
    chosenFromDate,
    chosenToDate,
    storeRecords,
    groupRecords,
  } = useSelector((state: RootState) => state?.filterSelection);

  //authorization
  if (userContext) {
    var { roleName, groupType } = userContext;
  }
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));

  const [isGrindOrMain, setIsGrindOrMain] = useState(
    userContext?.groupType === 3 ? "2" : "1"
  );

  // Grind Data
  const [grindData, setGrindData] = useState([]);
  const [trimData, setTrimData] = useState([]);
  const [cleanData, setCleanData] = useState([]);
  const [grindDataFiltered, setGrindDataFiltered] = useState([]);
  const [grindDataFilteredDate, setGrindDataFilteredDate] = useState([]);

  const [choosenDateData, setChoosenDateData] = useState([]);
  // end Grind Data
  const [storeName, setStoreName] = useState([]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(
    userContext && userContext.groupType && userContext.groupType === 2
      ? 2
      : userContext?.groupType === 3
      ? 3
      : 1
  );
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const mainOrGrind =
    userContext?.groupType !== 3
      ? [
          { value: "1", label: "Main" },
          { value: "2", label: "Grind" },
          { value: "3", label: "Placement" },
        ]
      : [{ value: "2", label: "Grind" }];

  // chart data
  const [dateTimeLabel, setDateTimeLabel] = useState([]);

  const [filteredDateTimeData, setFilteredDateTimeData] = useState([]);

  //end region

  // datetimes
  function filterObjectsByDate(data) {
    var DatabyDate = [];

    data.forEach(function (veri) {
      var rebuild_DateTime = moment(veri.rebuild_DateTime).format("YYYY-MM-DD");
      if (!DatabyDate.includes(rebuild_DateTime)) {
        DatabyDate.push(rebuild_DateTime);
      }
    });

    const groupedByOrder = DatabyDate.reduce((acc, obj) => {
      const order = obj.blind_Order;
      acc[order] = acc[order] || { blind_Order: order, totalLoadedQuantity: 0 };
      acc[order].totalLoadedQuantity += obj.loaded_Quantity_Case;
      return acc;
    }, {});
    return DatabyDate;
  }

  function processObjectsByDateAndOrder(objects) {
    const groupedByDate = objects.reduce((acc, obj) => {
      const date = moment(obj.rebuild_DateTime).format("YYYY-MM-DD");
      acc[date] = acc[date] || [];
      acc[date].totalLoadedQuantity =
        (acc[date].totalLoadedQuantity ? acc[date].totalLoadedQuantity : 0) +
        (obj.loaded_Quantity_Case ? obj.loaded_Quantity_Case : 0);
      acc[date].push(obj);
      return acc;
    }, {});
    setGrindDataFilteredDate(groupedByDate);
    const result = Object.keys(groupedByDate).map((date) => {
      const objectsInDate = groupedByDate[date];
      const filteredByOrder = objectsInDate.reduce((acc, obj) => {
        const order = obj.blind_Order;
        acc[order] = acc[order] || {
          blind_Order: order,
          totalLoadedQuantity: 0,
          comments: obj.comments,
          rebuild_DateTime: obj.rebuild_DateTime,
        };
        acc[order].totalLoadedQuantity += Number(obj.loaded_Quantity_Case);
        return acc;
      }, {});

      const filteredArray = Object.values(filteredByOrder);

      return {
        [date]: filteredArray,
      };
    });

    return result;
  }

  useEffect(() => {
    setDateTimeLabel(filterObjectsByDate(grindData));

    setFilteredDateTimeData(processObjectsByDateAndOrder(grindData));
  }, [grindData]);

  const SelectMainOrGrind = (event) => {
    setIsGrindOrMain(event);
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  useEffect(() => {
    const resultDate = Object.keys(groupedByDate).map((date) => ({
      rebuild_DateTime: moment(date).format("YYYY-MM-DD"),
      distinctStoreIDs: [
        ...new Set(groupedByDate[date].map((obj) => obj.store_ID)),
      ].length,
      totalStore: groupedByDate[date].map((obj) => obj.totalStore)[0],
    }));

    setGrindDataFiltered(resultDate.reverse());
  }, [grindData]);

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (userContext && userContext.roleName === "DM") {
      SettingsServices.getVendorStoreName(
        grouptypeID1,
        "GetJustStore",
        awgOrGotSelect
      )
        .then((result) => {
          if (groupType === 2) {
            result?.records?.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result?.records?.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray?.length >= 1) {
            result.records = result?.records?.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          // dispatch(setStoreRecord({
          //   storeRecords: result?.records
          // }));
          var setArrayID = [];
          var setArrayName = [];
          result?.records?.filter((item) => {
            return setArrayID.push(item.store_ID);
          });
          result.records.filter((item) => {
            return setArrayName.push(item.store_Name);
          });
          // dispatch(
          //   setStoreRecord({
          //     storeRecords: result?.records,
          //   })
          // );
          setStoreName(setArrayName);

          // setUserStore(setArrayID[0]);

          setUserStore(
            userContext?.groupType === 3 && userContext?.groupTypeId === 0
              ? null
              : setArrayID[0]
          );
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (awgOrGotSelect) {
      SettingsServices.getVendorStoreName(
        grouptypeID1,
        "GetJustStore",
        groupType
      )
        .then((result) => {
          if (groupType === 2 || groupType === 3) {
            result?.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result?.records.filter((item) => {
            return setArrayID.push(item.store_ID);
          });
          result?.records.filter((item) => {
            return setArrayName.push(item.store_Name);
          });

          // dispatch(
          //   setStoreRecord({
          //     storeRecords: result?.records,
          //   })
          // );

          setStoreName(setArrayName);
          //setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          // dispatch(setStoreRecord({
          //   storeRecords: result?.records
          // }));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  useEffect(() => {
    if (
      props.userContext &&
      props.userContext.store &&
      props.userContext.store !== 0
    ) {
      getStoreName(props.userContext.store);
    }
  }, [props.userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 3
    ) {
      GetGroupAndStoreByTypes("3", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2" || IDType === "3") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }

          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(
            userContext?.groupType === 3 && userContext?.groupTypeId === 0
              ? null
              : setArrayID[0]
          );
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        // dispatch(
        //   setGroupRecord({
        //     groupRecords: result,
        //   })
        // );
        //console.log(result);

        setGroupAndGroupID(result);
        // dispatch(setGroupRecord({
        //   groupRecords: result
        // }))
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const groupedByDate = grindData.reduce((acc, obj) => {
    const date = obj.rebuild_DateTime;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(obj);
    return acc;
  }, {});
  const State = {
    series: [
      {
        name: "Grind",
        data: grindDataFiltered.map((item) => {
          return item.distinctStoreIDs;
        }),
      },
    ],
    options: {
      chart: {
        type: "bar" as ValidChartType,
        width: 300,
        events: {
          click(event, chartContext, config) {
            let ID = config.config.xaxis.categories[config.dataPointIndex];
            setChoosenDateData(grindDataFilteredDate[ID]);
            setOpen(true);
          },
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: grindDataFiltered.map((item) => {
          return item.totalStore;
        }),
      },
      stroke: {
        show: false,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: grindDataFiltered.map((item) => {
          return item.rebuild_DateTime;
        }),
        title: {
          text: "Date Time",
        },
      },
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        autoSelected: "zoom",
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        reset: "Reset Zoom",
      },
      // yaxis: {
      //   show: true,
      //   title: {
      //     text: "Total Store",
      //   },

      // },

      yaxis: {
        title: {
          text: "Total Store",
        },
        min: 0,
        max:
          grindDataFiltered &&
          grindDataFiltered[0] &&
          grindDataFiltered[0].totalStore,
        floating: false,
      },
    },
  };
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  // if (roleName !== "AWG" && groupType !== 2) {
  if (roleName !== "AWG" && groupType !== 2) {
    return (
      <div className="Container">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          isVendor={false}
          SelectMainOrGrind={SelectMainOrGrind}
          mainOrGrind={mainOrGrind}
          isDashboard={true}
          isCalendar={isGrindOrMain !== "3" ? true : false}
        />
        {(userContext?.groupType === 3 || isGrindOrMain === "2") && (
          <>
            {/* {grindDataFiltered ? (
              <div>
                <div className="middle-side">
                  <Chart
                    options={State.options}
                    series={State.series}
                    type="bar"
                    height={400}
                  />
                </div>
              </div>
            ) : null} */}

            <div className="main-dashboard">
              <div className="left-side">
                <GrindMain
                  setGrindData={setGrindData}
                  userContext={userContext}
                  fromDate={fromDate}
                  groupTypeID={groupTypeID}
                  userStore={userStore}
                  setUserStore={setUserStore}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              </div>
              <div className="middleSide1">
                <TrimMain
                  setTrimData={setTrimData}
                  userContext={userContext}
                  fromDate={fromDate}
                  groupTypeID={groupTypeID}
                  userStore={userStore}
                  setUserStore={setUserStore}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              </div>
              <div className="right-side">
                <CleanMain
                  setCleanData={setCleanData}
                  userContext={userContext}
                  fromDate={fromDate}
                  groupTypeID={groupTypeID}
                  userStore={userStore}
                  setUserStore={setUserStore}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              </div>
            </div>
            <div style={{ maxWidth: "calc(100% - 10px" }}>
              <GrindLogTable
                userContext={userContext}
                fromDate={fromDate}
                toDate={toDate}
                userStore={userStore}
                groupTypeID={groupTypeID}
              />
            </div>
          </>
        )}{" "}
        {/*  Track IT */}
        {isGrindOrMain === "1" && userContext?.groupType !== 3 && (
          <div className="main-dashboard pt-4">
            <div className="left-side">
              <TrackITContainer
                userContext={userContext}
                fromDate={fromDate}
                groupTypeID={groupTypeID}
                userStore={userStore}
                setUserStore={setUserStore}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            </div>

            {/* Horizon grubu için gösterme */}
            {userContext && userContext.groupTypeId !== 176 && (
              <div className="middle-side mx-4">
                <MainScreen
                  userContext={userContext}
                  fromDate={fromDate}
                  groupTypeID={groupTypeID}
                  userStore={userStore}
                  setUserStore={setUserStore}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              </div>
            )}
            <div className="right-side">
              <MarkDownContainer
                groupTypeID={groupTypeID}
                userContext={userContext}
                fromDate={fromDate}
                userStore={userStore}
                setUserStore={setUserStore}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            </div>
          </div>
        )}
        {/*  Placement */}
        {isGrindOrMain === "3" && (
          <div>
            <div className="left-side-placement-dash">
              <PlacementContainer
                userContext={userContext}
                fromDate={fromDate}
                groupTypeID={groupTypeID}
                userStore={userStore}
                storeName={storeName}
                setUserStore={setUserStore}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            </div>
          </div>
        )}
        <Modal size="full" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div className="bg-container row m-0 mt-3 p-3">
                <div className="col-4 p-2 reportDescItem">
                  <div className="icon">
                    <Icon icon="material-symbols:storefront-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">{"Date Time "}</p>
                    <p className="m-0">
                      {choosenDateData &&
                        choosenDateData[0] &&
                        moment(choosenDateData[0].rebuild_DateTime).format(
                          "YYYY-MM-DD"
                        )}
                    </p>
                  </div>
                </div>

                <div className="col-1 p-2 reportDescItem">
                  <div className="d-flex">
                    <Dropdown
                      className="mx-2 button-fixed"
                      title="Export"
                      appearance="primary"
                      placement="bottomEnd"
                    >
                      <Dropdown.Item
                        icon={<FileDownloadIcon />}
                        onClick={() => {
                          exportToExcel(choosenDateData, "Detailed Grind Data");
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableComponent
              isFromModal={false}
              tableDetails={GrindConstants["GrindDetail"]}
              reportList={choosenDateData}
              iconColumn={"NA"}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{
                loaded_Quantity_Case: choosenDateData
                  ? choosenDateData["totalLoadedQuantity"]?.toFixed(2)
                  : 0,
              }}
              isTotalRowRequired={true}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="Container">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          isVendor={false}
          SelectMainOrGrind={SelectMainOrGrind}
          mainOrGrind={mainOrGrind}
          isDashboard={false}
          isCalendar={true}
        />
        <div className="main-dashboard">
          <div className="middle-side">
            <MainScreen
              userContext={userContext}
              fromDate={fromDate}
              groupTypeID={groupTypeID}
              userStore={userStore}
              setUserStore={setUserStore}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default MainDashboard;
