import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import Constants from "utils/constants";
import { Provider } from "react-redux";
import { store } from "./redux/store";
//#region MS Azure Login Instance
export const msalInstance = new PublicClientApplication(Constants.msalConfig);
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (
    event.eventType === EventType.LOGIN_FAILURE &&
    (event.error.errorMessage.includes("AADB2C90118") ||
      event.error.errorMessage.includes("AADB2C90091"))
  ) {
    // The user has forgotten their password.
    var loginRequest = {
      scopes: ["openid"], // optional Array<string>
    };
    msalInstance.loginRedirect(loginRequest);
  }
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
msalInstance.initialize();
//#endregion

// // Handle the redirect flows
// msalInstance
//   .handleRedirectPromise()
//   .then((authResult) => {})
//   .catch((error) => {
//     // Handle redirect error
//   });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App azureAuth={msalInstance} />
    </Provider>
  </React.StrictMode>
);
