export const ReportOOSConstants = {
    "GeneralConstants": [
        { Header: "Description", DataKey: "description", Width: 10, isFixed: true, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Order On", DataKey: "qty_Loaded", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:true},
        { Header: "Sent On", DataKey: "sent_ORD_Qty", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate1:true},
        { Header: "Inv On", DataKey: "inv_Qty", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate2:true},
        { Header: "Credit Request Qty", DataKey: "credit_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Request Credit Qty", DataKey: "user_Credit_Adj_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,Editable:true},
        { Header: "Reason from Invoice", DataKey: "reason_Discontinue", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Why Ord Adj", DataKey: "why_Order_Adj", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},


        { Header: "Order Now", DataKey: "user_Ord_Adj_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,Editable:true},
        { Header: "Order on", DataKey: "qty_Updated_1", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},
        { Header: "Order on ", DataKey: "qty_Updated_2", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},
        { Header: "Order on ", DataKey: "qty_Updated_3", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},
        { Header: "Order on ", DataKey: "qty_Updated_4", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},

        { Header: "Back Stock", DataKey: "back_Stock_SameDay_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Block", DataKey: "blocked", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Why Wait", DataKey: "why_Wait", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Wait Days from Today", DataKey: "days_To_Wait", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department_Store_Name", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Price Type", DataKey: "price_Type", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Item", DataKey: "item_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pack", DataKey: "pack", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Last Inv Qty", DataKey: "last_Invoice_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Inv Date", DataKey: "last_Invoice_Qty_date", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Last Inv Short", DataKey: "last_Invoice_Short", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Inv Date", DataKey: "last_Invoice_Short_Date", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
    ],

    "ReasonConstants": [
        { Header: "Description", DataKey: "description", Width: 10, isFixed: true, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Order On", DataKey: "qty_Loaded", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:true},
        { Header: "Sent On", DataKey: "sent_ORD_Qty", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate1:true},
        { Header: "Inv On", DataKey: "inv_Qty", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate2:true},
        //{ Header: "Credit Request Qty", DataKey: "credit_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Request Credit Qty", DataKey: "user_Credit_Adj_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,Editable:true},
        { Header: "Reason from Invoice", DataKey: "reason_Discontinue", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Why Ord Adj", DataKey: "why_Order_Adj", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},


        { Header: "Order Now", DataKey: "user_Ord_Adj_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,Editable:true},
        { Header: "Order on", DataKey: "qty_Updated_1", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},
        { Header: "Order on ", DataKey: "qty_Updated_2", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},
        { Header: "Order on ", DataKey: "qty_Updated_3", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},
        { Header: "Order on ", DataKey: "qty_Updated_4", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1,addDate:false},

        { Header: "Back Stock", DataKey: "back_Stock_SameDay_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Block", DataKey: "blocked", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Why Wait", DataKey: "why_Wait", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Wait Days from Today", DataKey: "days_To_Wait", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department_Store_Name", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Price Type", DataKey: "price_Type", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Item", DataKey: "item_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pack", DataKey: "pack", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Last Inv Qty", DataKey: "last_Invoice_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Inv Date", DataKey: "last_Invoice_Qty_date", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Last Inv Short", DataKey: "last_Invoice_Short", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Inv Date", DataKey: "last_Invoice_Short_Date", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
    ],



    "DetailConstants": [
        { Header: "Day", DataKey: "dd", Width: 20, isFixed: true, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Delivery", DataKey: "delivery_Date1", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Ord Item", DataKey: "adJ_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Adv Ord", DataKey: "adV_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Ord", DataKey: "orD_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Prebook", DataKey: "pB_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Components", DataKey: "pbC_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "AMP", DataKey: "amP_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cost", DataKey: "cost", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "EBA", DataKey: "ebA_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Bill Back", DataKey: "billBack_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Scan Qty", DataKey: "scan_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Food Show", DataKey: "food_show_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Power Buy", DataKey: "power_Buy_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Sos", DataKey: "sos_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Price Type", DataKey: "price_Type", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Order Cost", DataKey: "orD_Cost", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Prebook Cost", DataKey: "prebook_Cost", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Movement Units", DataKey: "mvmt_qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Inv Qty", DataKey: "inv_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Inv Comp Qty", DataKey: "inv_Comp_Qty", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Delivery Date", DataKey: "delivery_Date", Width: 20, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        
    ]
    
}