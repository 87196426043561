import { useEffect, useRef, useState } from "react";
import {
  Button,
  CheckPicker,
  Dropdown,
  IconButton,
  Modal,
  PickerHandle,
  SelectPicker,
  InputPicker,
} from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../../services/reportServices";
import Constants from "../../../.././utils/constants";
import TableComponent from "../../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import Calendar from "../../../common/CalendarComponent";
import { ReportConstantsDepartment } from "./reportMarkdownDepartmentConstants";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ReportMaster, TableHeaderConfig } from "../../../../models";
import { capitalCase } from "change-case";
import { addDays } from "date-fns";
import ReportMarkdownDetailComponent from "../MarkdownStore/reportMarkdownDetailComponent";
import * as AuthService from "../../../../services/authService";
import * as SettingsServices from "../../../../services/settingsServices";
import * as UMSService from "../../../../services/umsService";

const ReportMarkdownDepMain = (props: any) => {
  //#region Props
  const { handleSpinner, userContext = {} } = props;
  const { groupType } = userContext ?? {};
  //#endregion

  //#region Report API Filters
  // Calendar
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  const scrollref = useRef<HTMLDivElement>();
  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Markdown & Shrink",
  });
  const [isDetailView, setDetailView] = useState(false);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  //#endregion

  //#region Reports Master Lists
  const [reportData, setReportData] = useState(new ReportMaster());
  //#endregion
  //#region Reports Tables Config
  // Headers
  const [groupTableHeaders, setGroupTableHeaders] = useState([]);
  const [listTableHeaders, setListTableHeaders] = useState([]);
  const [detailTableHeaders, setDetailTableHeaders] = useState([]);

  // Totals
  const [groupTableTotals, setGroupTableTotals] = useState([]);
  const [detailTotal, setDetailTotal] = useState([]);
  const [listTableTotals, setListTableTotals] = useState([]);
  // reports List
  const [groupTableReports, setGroupTableReports] = useState([]);
  const [groupTableMasterReports, setGroupTableMasterReports] = useState([]);
  const [listTableReports, setListTableReports] = useState([]);
  const [detailTableReports, setDetailTableReports] = useState([]);
  // No Data found
  const [DisplayNoDatatlabel, setDisplayNoDatatlabel] = useState(false);
  //#endregion
  //#region Filters
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedColumnFilters, setSelectedColumnFilters] = useState({});
  const [filterCount, setFilterCount] = useState({});
  const [isfilterSelected, setFilterSelected] = useState(false);
  const picker = useRef<PickerHandle>();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [clickedFilterData, setClickedFilterData] = useState(null);
  const [clickedFilterDataMaster, setClickedFilterDataMaster] = useState(null);
  const [isDataloaded, setIsDataloaded] = useState(false);
  //#endregion

  const tabsList: any[] = [
    { value: "1", label: "Markdown & Shrink" },
    { value: "2", label: "Markdown" },
    { value: "3", label: "Shrink" },
    { value: "4", label: "Reclaim Eligible" },
  ];

  ///////////////////////////////
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        result.records.sort((a, b) => {
          const Store_A = a.store_ID;
          const Store_B = b.store_ID;

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        if (storesArray.length >= 1) {
          result.records = result.records.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
        }
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });

        setStoreName(setArrayName);
        setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    setUserStore("");
    // if (storeID) {
    //   setUserStore(storeID[0]);
    // }
  };
  const SelectAwgOrGot = (event) => {
    //console.log(event.target.value);
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
  };

  //////////////////////////////

  useEffect(() => {
    setIsCollapsed(false);
  }, []);

  useEffect(() => {
    setDetailView(false);
    setDisplayNoDatatlabel(false);
    setGroupTableHeaders([]);
    setGroupTableTotals([]);
    setGroupTableReports([]);
    getReportData();
  }, [props.userContext, fromDate, toDate, userStore, groupTypeID]);

  useEffect(() => {
    handleDataChange();
    handleDataDetailChange();
  }, [reportData, selectedTab]);

  useEffect(() => {
    columnFiltersData();
  }, [groupTableHeaders, selectedTab]);

  useEffect(() => {
    if (
      props.userContext &&
      props.userContext.store &&
      props.userContext.store !== 0
    ) {
      getStoreName(props.userContext.store);
    }
  }, [props.userContext]);

  //#region Common Functions
  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};
    headers.forEach((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              )
          : "";
    });
    return totalObj;
  };

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClickedFilterData(null);
    setClickedFilterDataMaster(null);
  };
  //#endregion

  //#region Markdown Report
  const handleDataChange = () => {
    let headers = [];
    let data = [];
    let totals = [];
    headers = getMarkdownHeaders(reportData.headers, false);
    data = handleMarkdownData(false);
    totals = getTotalRowData(data, headers);
    setGroupTableHeaders(headers);
    setGroupTableTotals(totals);
    setGroupTableMasterReports(data);
    setGroupTableReports(data);
    setDisplayNoDatatlabel(isDataloaded && data.length === 0 ? true : false);
  };
  const handleDataDetailChange = () => {
    let data = [];
    let totals = [];
    data = detailTableReports;
    const totalMarkdown = data?.reduce(
      (acc, item) => acc + Number(item["MarkDownLoss"]),
      0
    );
    const totalPnt = data?.reduce(
      (acc, item) => acc + (item["pnt_Count"] ?? 0),
      0
    );

    totals = [{ markdownLoss: totalMarkdown, totalPnt: totalPnt }];
    setDetailTotal(totals);
    setDisplayNoDatatlabel(isDataloaded && data.length === 0 ? true : false);
  };

  const getReportData = async () => {
    var { groupType, groupTypeId, store, roleName } = userContext;
    if (roleName === "Super Admin" && userStore === "") {
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdownDepartment(
        groupTypeID,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (
      roleName === "Super Admin" ||
      (store === 0 && groupTypeID !== 0 && userContext.groupTypeName === "")
    ) {
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdownDepartment(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userContext && userContext.roleName === "DM") {
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdown(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      if (store !== 0) {
        groupTypeId = store;
        groupType = 1;
      } else {
        groupType = 2;
      }
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdownDepartment(
        groupTypeId,
        groupType,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const handleReportData = (response: any) => {
    const { records = [], uniqueReasons = [] } = response;
    const _reportData = { ...reportData };
    _reportData.headers = uniqueReasons;
    _reportData.reportList = records.map((item) => {
      item["FormatedPrintedDate"] =
        item.date_Printed &&
        moment(item.date_Printed).format("MM-DD-YYYY HH:mm A");
      item["Badge_Type"] = item.reg_Price_Keyed > 0 ? " Yes" : "No";
      item["Description"] = item.description !== "" && item.description.trim();
      item["shrink_Scan_Time"] =
        item.shrink_Scan_Time && item.shrink_Scan_Time.trim() !== "00:00:00"
          ? moment(item.shrink_Scan_Time, ["HH:mm:ss"]).format("hh:mm:ss A")
          : "";

      item["MarkDownLoss"] = (item["reg_Total"] * item["disc_Percent"]) / 100;

      return item;
    });
    return _reportData;
  };

  const getMarkdownHeaders = (reasonHeaders, isGroup = false) => {
    const headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    headers.push({
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 150,
      TypeOfData: "Price",
      Icon: "reg_price_keyed",
    });
    const headerStart =
      ReportConstantsDepartment[
        isGroup ? "GroupDeptDataTable" : "GroupDataTable"
      ];
    return [...headerStart, ...headers];
  };

  const handleMarkdownData = (isGroup = false, isDetailed = false) => {
    let _reportData = [];
    const { value: selectedTabValue } = selectedTab;
    switch (selectedTabValue) {
      case "2":
        _reportData = reportData.reportList.filter((res) => {
          return res.shrink_Confirmation_ID === "";
        });
        break;
      case "3":
        _reportData = reportData.reportList.filter((res) => {
          return res.shrink_Confirmation_ID !== "";
        });
        break;
      case "4":
        _reportData = reportData.reportList.filter((res) => {
          return res.reclaim && res.reclaim.trim() === "Y";
        });
        break;
      default:
        _reportData = [...reportData.reportList];
        break;
    }

    if (!isDetailed) return groupMarkdownData(_reportData, isGroup);
    else return _reportData;
  };

  const groupMarkdownData = (_reportData: Array<any>, isGroup = false) => {
    const { value: selectedTabValue } = selectedTab;
    const reportGrpArr = Object.values(
      _reportData.reduce((repGrp, report) => {
        const { store_ID, department } = report;
        const _key = isGroup ? `${store_ID}_${department}` : department;
        if (repGrp[_key]) {
          repGrp[_key].push(report);
        } else {
          repGrp[_key] = [report];
        }
        return repGrp;
      }, {})
    ).map((rep: Array<any>) => {
      const obj: any = {
        department: rep[0].department,
        store_ID: rep[0].store_ID,
        store_ID1: rep[0].client_Store_N,
        total: 0,
        Badge_Type: "No",
      };
      if (isGroup) obj.department = rep[0].department;
      const priceKeyed = rep.reduce(
        (acc, item) => acc + (item["reg_Price_Keyed"] ?? 0),
        0
      );
      obj.Badge_Type = priceKeyed > 0 ? " Yes" : "No";
      obj.reg_price_keyed = priceKeyed > 0 ? true : false;
      reportData.headers.forEach((head) => {
        const _reasonPrice = rep
          .filter((_reportFltr) => _reportFltr.reason === head)
          .reduce(
            (acc, item) =>
              acc +
              (item[selectedTabValue === "3" ? "reg_Price" : "reg_Total"] ?? 0),
            0
          ); //).toFixed(2)
        obj[head] = _reasonPrice;
        obj.total += _reasonPrice;
      });
      return obj;
    });
    return reportGrpArr ?? [];
  };
  //#endregion

  //#region  Table Functions
  const onGroupReportRowClick = (data: any, isShowAll = false) => {
    setClickedFilterDataMaster(data);
    const _reportsList = handleMarkdownData(true);
    const _groupTableReports = groupTableReports.map((lst) => lst.department);
    const _filteredList = isShowAll
      ? _reportsList.filter((_x) => {
          let selected = _groupTableReports.some(
            (lst) => lst === _x.department
          );
          return selected;
        })
      : _reportsList.filter((_x) => _x.department === data.department);
    const _data = { ...data };
    delete _data.store_ID1;
    const _filteredTotalRow = isShowAll ? groupTableTotals : _data;
    const _headers = getMarkdownHeaders(reportData.headers, true);
    setListTableReports(_filteredList);
    setListTableTotals(_filteredTotalRow);
    setListTableHeaders(_headers);
    setDetailView(false);
    handleOpen("full");
  };
  const onDetailReportRowClick = (data: any, isShowAll = false) => {
    setClickedFilterData(data);
    const _reportsList = handleMarkdownData(false, true);
    const _listTableReports = listTableReports.map((lst) => lst.department);
    const _filteredList = isShowAll
      ? _reportsList.filter((_x) => {
          let selected = true;
          if (data && data.department !== "")
            selected = _x.department === data.department;
          else
            selected = _listTableReports.some((lst) => lst === _x.department);
          return selected;
        })
      : _reportsList.filter(
          (_x) =>
            _x.store_ID === data.store_ID &&
            _x.department.trim() === data.department.trim()
        );

    // Add Markdown Loss column and data
    _filteredList.map(
      (list) =>
        (list["MarkDownLoss"] =
          list["reg_Total"] * (list["disc_Percent"] / 100))
    );

    const _data = { ...data };
    delete _data.store_ID1;
    const _headers = ReportConstantsDepartment[selectedTab.label]; // getMarkdownHeaders(reportData.headers, true);
    setDetailTableReports(_filteredList);
    setDetailTableHeaders(_headers);
    setDetailView(true);
  };

  const handleShowAll = () => {
    onGroupReportRowClick(null, true);
  };
  const handleShowAllDetails = () => {
    onDetailReportRowClick(listTableTotals, true);
  };
  //#endregion

  //#region Filters
  const columnFiltersData = () => {
    let filtersArr = [];
    groupTableHeaders.forEach((value) => {
      value.isSearch &&
        value.searchGroup.some((x) => x === groupType) &&
        filtersArr.push({
          key: value.DataKey,
          label: value.Header,
          data: [
            ...new Set(groupTableMasterReports.map((_) => _[value.DataKey])),
          ].sort((a, b) => {
            let x = a ? a : "";
            let y = b ? b : "";
            return x
              .toString()
              .localeCompare(y.toString(), undefined, { numeric: true });
          }),
        });
    });
    columnFiltersOnSelect();
    setColumnFilters(filtersArr);
  };

  const columnFiltersOnSelect = () => {
    let selectedFiltersArr = {};
    groupTableHeaders.forEach((value) => {
      if (value.isSearch && value.searchGroup.some((x) => x === groupType)) {
        selectedFiltersArr[value.DataKey] = [];
      }
    });
    setFilterCount(selectedFiltersArr);
    setSelectedColumnFilters(selectedFiltersArr);
    filterSelected(selectedFiltersArr, selectedFiltersArr);
  };

  const filterSelected = (filterCount, selectedData) => {
    let isSelected = false;
    for (const data in filterCount) {
      if (
        filterCount[data] &&
        selectedData[data] &&
        filterCount[data].length !== selectedData[data].length
      )
        isSelected = true;
    }
    setFilterSelected(isSelected);
  };

  const getCheckPickerData = (result) => {
    let valueArr = [];
    if (result.data) {
      valueArr = result.data.map((res) => {
        return { label: res !== "" ? res : "(Empty)", value: res };
      });
    }
    return valueArr;
  };

  const onFilterClick = (selectedColumnFilters) => {
    let data = groupTableMasterReports;
    data = data.filter((result) => {
      let selected = true;
      for (let prop in selectedColumnFilters) {
        if (selected) {
          selected =
            selectedColumnFilters[prop] &&
            selectedColumnFilters[prop].length > 0
              ? selectedColumnFilters[prop].some(
                  (item) => item.toString() === result[prop].toString()
                )
              : true;
        }
      }
      return selected;
    });
    setGroupTableReports(data);
    setDisplayNoDatatlabel(data.length === 0);
  };

  const clearFilter = () => {
    columnFiltersOnSelect();
    setGroupTableReports(handleMarkdownData());
    filterSelected(filterCount, filterCount);
  };
  //#endregion

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };

  //#endregion
  const scroll = (scrollOffset) => {
    scrollref.current.scrollLeft += scrollOffset;
  };
  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });
  return (
    <>
      <div className="TrackITReportHeader">
        <>
          <div className="TrackITReportHeaderItems">
            <InputPicker
              className="InputPicker"
              onChange={(e) =>
                setSelectedTab({ ...selectedTab, value: String(e) })
              }
              size="lg"
              placeholder="Markdown & Shrink"
              data={data}
              cleanable={false}
            />
          </div>

          <>
            {userContext &&
              userContext.roleName &&
              (userContext.roleName === "Super Admin" ||
                (userContext.groupTypeId !== "0" &&
                  userContext.groupTypeName === "")) && (
                <div className="TrackITReportHeaderItems">
                  {" "}
                  {userContext && userContext.roleName === "Super Admin" ? (
                    <div className="GroupStoreSide">
                      <SelectPicker
                        placeholder="GOT"
                        onChange={SelectAwgOrGot}
                        className="header-bar-item"
                        label="Group Type"
                        data={awgOrGot.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                      />
                      <SelectPicker
                        onChange={SelectGroupType}
                        label="Group"
                        className="header-bar-item"
                        data={groupAndGroupID.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                      />
                      <SelectPicker
                        onChange={onOptionChangeHandler}
                        label="Store"
                        className="header-bar-item"
                        //   placeholder={
                        //     storeName && storeName[0] ? storeName[0] : "All"
                        //   }
                        placeholder={"All"}
                        virtualized
                        data={storeName.map((item, index) => ({
                          label: item,
                          value: index,
                        }))}
                      />
                    </div>
                  ) : userContext && userContext.store !== 0 ? (
                    <>
                      <label
                        style={{ height: "30px", fontWeight: 700 }}
                        className=" alert"
                      >
                        Store: {storeNameHeader}
                      </label>{" "}
                    </>
                  ) : userContext &&
                    userContext.groupTypeId &&
                    userContext.groupTypeId !== "" ? (
                    <>
                      {" "}
                      <SelectPicker
                        onChange={onOptionChangeHandler}
                        label="Store"
                        className="header-bar-item"
                        placeholder={
                          storeName && storeName[0] ? storeName[0] : "All"
                        }
                        data={storeName.map((item, index) => ({
                          label: item,
                          value: index,
                        }))}
                      />
                    </>
                  ) : (
                    <>
                      <SelectPicker
                        onChange={SelectGroupType}
                        label="Group"
                        className="header-bar-item"
                        virtualized
                        data={groupAndGroupID.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                      />
                      {
                        <>
                          {" "}
                          <SelectPicker
                            onChange={onOptionChangeHandler}
                            label="Store"
                            className="header-bar-item"
                            placeholder={
                              storeName && storeName[0] ? storeName[0] : "All"
                            }
                            virtualized
                            data={storeName.map((item, index) => ({
                              label: item,
                              value: index,
                            }))}
                            style={{ width: 224 }}
                          />
                        </>
                      }
                    </>
                  )}
                </div>
              )}

            {userContext && userContext.roleName === "DM" && (
              <>
                <div
                  className="col-7 align-content-start flex-nowrap  align-items-center"
                  style={{ marginLeft: "10px" }}
                >
                  <SelectPicker
                    onChange={onOptionChangeHandler}
                    label="Store"
                    className="header-bar-item"
                    placeholder={
                      storeName && storeName[0] ? storeName[0] : "All"
                    }
                    virtualized
                    data={storeName.map((item, index) => ({
                      label: item,
                      value: index,
                    }))}
                    style={{ width: 224 }}
                  />
                </div>
              </>
            )}
          </>
        </>
        <div className="TrackITReportHeaderItems">
          <div className="d-flex justify-content-between align-items-center">
            <Calendar
              value={[fromDate, toDate]}
              calenderOnClick={calenderOnClick}
            />
          </div>
        </div>
      </div>

      <div className="mt-2 d-flex justify-content-end align-items-end">
        {groupTableReports.length > 0 && (
          <>
            {" "}
            <div className="">
              <span className="badge-legend me-3">
                {" "}
                Note: <Icon className="flag-icon2" icon="ep:flag" /> Regular
                Price Keyed
              </span>{" "}
            </div>
            {" | "}
          </>
        )}
        {columnFilters && columnFilters.length > 0 && (
          <div
            id="filterdiv"
            className="mx-3 filter"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            onClick={toggleCollapse}
          >
            <Icon icon="material-symbols:filter-alt-outline-sharp" /> Filters
          </div>
        )}
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(groupTableReports, "Markdown Report");
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>

      {columnFilters && columnFilters.length > 0 && (
        <div
          className={`collapse p-2 mt-3 boxallign ${isCollapsed ? "" : "show"}`}
          id="collapseExample"
        >
          <div className="card card-body totalCard">
            {columnFilters.map((result) => {
              return (
                <div className="collapsedData2 pe-2">
                  <CheckPicker
                    sticky
                    preventOverflow
                    className="w-100 filtersPicker"
                    menuMaxHeight={190}
                    ref={picker}
                    appearance="default"
                    size="xs"
                    renderValue={(value, items) => {
                      const _value = [...value].sort((a, b) => {
                        let x = a ? a : "";
                        let y = b ? b : "";
                        return x
                          .toString()
                          .localeCompare(y.toString(), undefined, {
                            numeric: true,
                          });
                      });
                      _value[0] === "" && _value.splice(0, 1, "(Empty)");
                      return (
                        <>
                          {_value.length < 3
                            ? _value.splice(0, 2).join(", ")
                            : _value[0] !== "(Empty)"
                            ? _value.splice(0, 2).join(", ")
                            : _value.splice(1, 2).join(", ")}{" "}
                          {_value.length > 0 ? (
                            <span className="roundOff">
                              {" "}
                              {`+${_value.length}`}
                            </span>
                          ) : (
                            ``
                          )}
                        </>
                      );
                    }}
                    data={getCheckPickerData(result)}
                    style={{ width: 224 }}
                    value={selectedColumnFilters[result.key]}
                    placeholder={result.label}
                    renderMenuItem={(label, item) => {
                      return (
                        <div className="d-flex flex-column">
                          <i className="rs-icon rs-icon-user" /> {label}
                        </div>
                      );
                    }}
                    onChange={(value, event) => {
                      let selectedData = { ...selectedColumnFilters };
                      selectedData[result.key] = value;
                      setSelectedColumnFilters(selectedData);
                      filterSelected(filterCount, selectedData);
                      onFilterClick(selectedData);
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="footer text-end boxdetails">
            <Button
              appearance="ghost"
              className="mx-2"
              size="xs"
              disabled={!isfilterSelected}
              onClick={() => {
                clearFilter();
              }}
            >
              Clear All
            </Button>
            <IconButton
              appearance="ghost"
              style={{ padding: "0.35rem" }}
              icon={<Icon icon="ic:twotone-close" />}
              size="xs"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
            />
          </div>
        </div>
      )}

      <TableComponent
        isFromModal={false}
        tableDetails={groupTableHeaders}
        reportList={groupTableReports}
        iconColumn={"department"}
        onTableRowClick={onGroupReportRowClick}
        reportType={""}
        totalTableRow={groupTableTotals}
        isTotalRowRequired={true}
        DisplayNoDatatlabel={DisplayNoDatatlabel}
        showAll={true}
        handleShowAll={handleShowAll}
      />
      <Modal size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isDetailView ? (
              <span
                className="mx-2 filter"
                onClick={() => {
                  setDetailView(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
            ) : (
              <span
                className="mx-2 filter"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
            )}
            Markdown Details{isDetailView ? "" : " By Department"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isDetailView ? (
            <ReportMarkdownDetailComponent
              clickedFilterData={clickedFilterData}
              clickedFilterDataMaster={clickedFilterDataMaster}
              tableDetails={detailTableHeaders}
              reportList={detailTableReports}
              exportToExcel={exportToExcel}
              totalTableRow={{}}
              isTotalRowRequired={false}
              isTableCustomStriped={
                groupType === 2
                  ? clickedFilterDataMaster
                    ? clickedFilterDataMaster["store_ID"] === ""
                    : clickedFilterData
                    ? clickedFilterData["store_ID"] === ""
                      ? true
                      : false
                    : true
                  : false
              }
            />
          ) : (
            // <TableComponent
            //     isFromModal={false}
            //     tableDetails={detailTableHeaders}
            //     reportList={detailTableReports}
            //     iconColumn={"NA"}
            //     onTableRowClick={() => { }}
            //     reportType={""}
            //     totalTableRow={{}}
            //     isTotalRowRequired={false}
            //     DisplayNoDatatlabel={DisplayNoDatatlabel}
            //     isTableCustomStriped={groupType === 2 ? clickedFilterDataMaster ? clickedFilterDataMaster['store_ID'] === "" : clickedFilterData ? clickedFilterData['store_ID'] === '' ? true : false : true : false}
            // />
            <TableComponent
              isFromModal={false}
              tableDetails={listTableHeaders}
              reportList={listTableReports}
              iconColumn={"store_ID"}
              onTableRowClick={onDetailReportRowClick}
              reportType={""}
              totalTableRow={listTableTotals}
              isTotalRowRequired={true}
              DisplayNoDatatlabel={DisplayNoDatatlabel}
              showAll={true}
              handleShowAll={handleShowAllDetails}
              isTableCustomStriped={
                groupType === 2 && clickedFilterDataMaster == null
                  ? true
                  : false
              }
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportMarkdownDepMain;
