import React, { useEffect, useState } from "react";
import * as ReportService from "../../../services/reportServices";
import moment from "moment";
import { useMediaQuery } from "usehooks-ts";
import { Doughnut, Bar } from "react-chartjs-2";
import { Modal, Grid, Row, Col, Dropdown } from "rsuite";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Icon } from "@iconify/react";
import TableComponent from "../../common/tableComponent";
import { GrindConstants } from "./GrindConstants";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";

const GrindMain = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [data1, setData1] = useState([]);
  //DetailPage Region
  const [isDetailView, setDetailView] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // region end
  const [showStore, setShowStore] = useState("");
  const [showTotalLBS, setShowTotalLBS] = useState("");
  const [showItemsGround, setShowItemsGround] = useState("");

  const [newPercentagesData, setNewPercentagesData] = useState(0);
  const {
    userContext,
    fromDate,
    groupTypeID,
    toDate,
    userStore,
    setUserStore,
    setGrindData,
  } = props;
  const getGrindData = async (
    GroupOrStoreID,
    ChartTypeID,
    StartTime,
    EndTime,
    UserType
  ) => {
    ReportService.getGrindLogData(
      GroupOrStoreID,
      ChartTypeID,
      moment(StartTime).format("YYYY-MM-DD"),
      moment(EndTime).format("YYYY-MM-DD"),
      UserType
    )
      .then((result) => {
        result.map((item) => {
          item.ShowStores = `${item.grD_Store} / ${item.totalStore}`;
          item.rebuild_DateTime = moment(item.rebuild_DateTime).format(
            "YYYY-MM-DD hh:mm:SS"
          );
          item.sumObLBS = item.sumObLBS
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          return item;
        });

        setShowStore(result[0].ShowStores);
        setShowTotalLBS(result[0].sumObLBS);
        setShowItemsGround(result[0].items_Ground);
        dataArray.push(result[0].grD_Store);
        dataArray.push(result[0].totalStore - result[0].grD_Store);
        setData1(result);
        setGrindData(result);
        setNewPercentagesData(calculatePercentages(dataArray));
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
      })
      .finally(() => {});
  };
  useEffect(() => {
    const gg1 = groupTypeID
      ? groupTypeID
      : userContext && userContext.groupTypeId;

    if (userContext && userContext.store !== 0) {
      getGrindData(userContext.store, 1, fromDate, toDate, "Store");
    } else if (userStore) {
      getGrindData(userStore, 1, fromDate, toDate, "Store");
    } else if (gg1) {
      getGrindData(gg1, 1, fromDate, toDate, "Group");
    } else if (
      !userStore &&
      userContext?.groupType === 3 &&
      userContext?.groupTypeId === 0 &&
      !userStore &&
      !gg1
    ) {
      getGrindData(0, 1, fromDate, toDate, "All");
    }
  }, [userContext, toDate, fromDate, groupTypeID, userStore]);

  let dataArray = [];

  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  ///////// charts

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,

    plugins: {
      datalabels: {
        display: false,
        color: "#575757;",
        font: { weight: 900, size: 10 },
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: `From ${fromDate} To ${toDate} Grind`,
      },

      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  const labels = ["GRD Store", "NOT GRD Store"];
  const data = {
    labels: labels,
    datasets: [
      {
        data: newPercentagesData,
        backgroundColor: ["#198754", "#a94442"],
        borderColor: ["#186BA1", "#19ABDE"],
        spacing: 20,
        hoverOffset: 2,
      },
    ],
  };

  return (
    <>
      <Grid fluid>
        <Row className="my-4">
          <div className="chart-heading">GRIND Recorded</div>
          <div className="programming-stats-dashboard">
            <div className="center-chart">
              <Doughnut options={options} data={data} />
            </div>
            <div className="details-chart">
              {data1 && data1[0] && (
                <ul>
                  {" "}
                  <li className="chart-li">
                    <div className="percantage11">{"Stores"}</div>
                    <span className="percantage">
                      {" " + showStore && showStore}
                    </span>
                  </li>
                  <li className="chart-li">
                    <div className="percantage11">{"Total LBS"}</div>
                    <span className="percantage">
                      {" " + showTotalLBS && showTotalLBS}
                    </span>
                  </li>
                  <li className="chart-li">
                    <div className="percantage11">{"Items Ground"}</div>
                    <span className="percantage">
                      {" " + showItemsGround && showItemsGround}
                    </span>
                  </li>
                </ul>
              )}
            </div>
            <div className="chart-li-item">
              {data1 && data1[0] && (
                <>
                  {" "}
                  <div
                    onClick={() => {
                      setOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                    className="chart-label-mainscreen"
                  >
                    <Icon
                      className="icon-size"
                      icon="ic:outline-remove-red-eye"
                    />
                    <span>Show Detail</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </Row>
      </Grid>
      <Modal size="full" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <span
              className="mx-2 filter"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Icon icon="ep:back" />
            </span>
            <div className="mt-2 d-flex justify-content-evenly align-items-center">
              Grind Details
              <Dropdown
                renderToggle={(_props, _ref) => (
                  <div className="filter" ref={_ref} {..._props}>
                    <Icon icon="pajamas:export" /> Export
                  </div>
                )}
                onClick={() => {
                  exportToExcel(data1, "Grind recorded");
                }}
                className="ms-2 me-0 button-fixed"
                appearance="link"
                placement="bottomEnd"
              ></Dropdown>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableComponent
            isFromModal={false}
            tableDetails={GrindConstants["Grind"]}
            reportList={data1}
            iconColumn={"NA"}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{
              loaded_Quantity_Case: showTotalLBS && showTotalLBS,
            }}
            isTotalRowRequired={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GrindMain;
