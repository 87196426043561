import { useEffect, useState, useRef } from "react";
import { Modal, Button, Grid, Row, Col, FlexboxGrid } from "rsuite";
import moment from "moment";
import MainChart from "./MainChart";
import MainChartBar from "./MainChartBar";

const MainControllerPercentage = (props: any) => {
  const { placementMainData, toDate, fromDate, groupTableTotals, userContext,storeName } =
    props;

  // previous next layeer

  const [firstLayer, setFirstLayer] = useState(true);
  const [secondLayer, setSecondLayer] = useState(false);
  const [thirdLayer, setThirdLayer] = useState(false);
  //#endregion
  var labels: any = [];
  const chartValue: any = [];
  // if has value of GroupTableTotals chartLabel take key.
  const entries = Object.entries(groupTableTotals);
  for (const [key, value] of entries) {
    if (key.length !== 0) {
      labels.push(key);
      chartValue.push(value);
    }
  }

  let ref3 = useRef();

  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <Col sm={24} md={24} xl={24} xxl={32}>
            {firstLayer && (
              <MainChartBar
                placementMainData={placementMainData}
                setFirstLayer={setFirstLayer}
                setSecondLayer={setSecondLayer}
                setThirdLayer={setThirdLayer}
                totalValue={groupTableTotals.total ? groupTableTotals.total : 0}
                value={chartValue}
                storeName={storeName}
                labels={labels}
                toDate={moment(toDate).format("MM/DD/YYYY")}
                fromDate={moment(fromDate).format("MM/DD/YYYY")}
                ref3={ref3}
              />
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default MainControllerPercentage;
